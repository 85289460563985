import React from 'react'
import { Table } from 'semantic-ui-react'
import moment from 'moment'

// Se non c'e' la key ritorna il value
function findKeyByValue(obj, value) {
    for (const key in obj) {
        if (obj[key] === value) {
            return key
        }
    }
    return String(value)
}

const BalanceTable = ({ events, types }) => {
    if (events.length === 0) return <div>No currency events found.</div>
    if (Object.keys(types).length === 0) return <div>No currency events found.</div>
    return (
        <Table celled padded>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell singleLine>Date</Table.HeaderCell>
                    <Table.HeaderCell>Currency</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell>Srv Date</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {events.map((event, index) => {
                    // console.log('types.actionType', event, types.actionType)
                    return (
                        <Table.Row key={index}>
                            <Table.Cell>{moment(event.timestamp).format('DD-MM-YY HH:mm:ss:SSS')}</Table.Cell>
                            <Table.Cell singleLine>
                                {/* {event.currencyType} -{' '} */}
                                <b>{findKeyByValue(types.currencyTypes, event.currencyType)}</b>
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {event.action === 'incr' ? '🟢' : '🔴'}
                            </Table.Cell>
                            <Table.Cell>
                                {/* {event.actionType} -{' '} */}
                                {findKeyByValue(types.actionType, event.actionType)
                                    .replaceAll('_', ' ')
                                    .toUpperCase()}
                            </Table.Cell>
                            <Table.Cell>
                                {event.action === 'incr' ? '+' : '-'}
                                {event.amount}
                            </Table.Cell>
                            <Table.Cell>{event.totalAmount}</Table.Cell>
                            <Table.Cell>{event.srvTimestamp && moment(event.srvTimestamp).format('DD-MM-YY HH:mm:ss:SSS')}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}

export default BalanceTable
