import { apiServer } from '../settings'
import { getToken } from './token'
// import createLog from '../utils/create-log'

const createGroup = ({ game, members, name }, done) => {
	// if (!userGuid) return done('missing userGuid')
	// if (!userAuthorization) return done('missing userAuthorization')
	// if (!game) return done('missing game')
	if (!game) return done('missing game')
	if (!members) return done('missing members')
	if (members.filter(m => m.role === 'admin').length === 0) return done('you must declare at least one admin')
	if (!name) return done('missing group name')

	const headers = new Headers({
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + getToken() 
	})

	const options = {
		method: 'POST',
		headers,
		mode: 'cors',
		cache: 'default',
		body: JSON.stringify({
			game,
			members,
			name,
			// log: createLog(userGuid, userAuthorization, game, `set user auth ${guid} ${value}`),
		}),
	}

	const url = `${apiServer}/group-create`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.group)
			if (!response.success) return done('response without success true|false')
		})
}

export default createGroup
