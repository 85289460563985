import React, { /*useLayoutEffect,*/ useEffect, useContext } from 'react'
// import { useLocation } from 'react-router-dom'
import { withRouter } from 'react-router'

import { Dropdown, Icon } from 'semantic-ui-react'
import { games } from './settings'
import { Context } from './Context'
import { useBannedUsersContext } from './Contexts/BannedUsersContext'
import { useBanStatsContext } from './Contexts/BanStatsContext'

// function useQuery() {
// 	return new URLSearchParams(useLocation().search)
// }

// const isAllowedGame = game => {
// 	return games.map(item => item.key).indexOf(game) !== -1
// }

const GameSelector = props => {
	const { history } = props
	const { game, setGame, setPlayer } = useContext(Context)
	const [_, setBannedUsers] = useBannedUsersContext()
	const [__, setBanStats] = useBanStatsContext()

	// const query = useQuery()
	// const routeGame = query.get('game')

	// console.log('RESET PROVA')
	// MI SA CHE NON SERVE, ERA UN TENTATIVO DI RENDERE GAME COME QUERY ELEMENT
	// useLayoutEffect(() => {
	// 	console.log('RESET PROVA', routeGame && routeGame !== game)
	// 	if (routeGame && routeGame !== game) {
	// 		if (!isAllowedGame(routeGame)) {
	// 			throw new Error('game in query is not allowed')
	// 		}
	// 		setGame(routeGame)

	// 		setResultFromApi()
	// 	}
	// })

	useEffect(() => {
		setBannedUsers()
		setBanStats()
	}, [game])

	return (
		<Dropdown
			button
			className="icon"
			floating
			labeled
			icon={
				<Icon style={{ marginTop: '4px' }} inverted color="black" name="gamepad" bordered />
			}
			placeholder="Select a game"
			fluid
			selection
			value={game}
			options={games}
			onChange={(_, data) => {
				history.push('/')
				setGame(data.value)
				setPlayer(null)
			}}
		/>
	)
}
export default withRouter(GameSelector)
