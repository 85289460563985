import React from 'react'
import { Table } from 'semantic-ui-react'
// import moment from 'moment'

//         type: 'monthly'
//         level: 'beginner',
//         period: '2023:1',
//         value: 666,

const JackpotsTable = ({ type, jackpots }) => {
    if (!jackpots) return <div>No jackpots found.</div>
    return (
        <Table celled padded>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Level</Table.HeaderCell>
                    <Table.HeaderCell>Period</Table.HeaderCell>
                    <Table.HeaderCell>Value</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {jackpots.map((jack, index) => {
                    // console.log('types.actionType', event, types.actionType)
                    return (
                        <Table.Row key={index}>
                            <Table.Cell>{type}</Table.Cell>
                            <Table.Cell>{jack.level}</Table.Cell>
                            <Table.Cell>{jack.period}</Table.Cell>
                            <Table.Cell>{jack.value}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}

export default JackpotsTable
