import React from 'react'
import { Table } from 'semantic-ui-react'
import moment from 'moment'

// Se non c'e' la key ritorna il value
function findKeyByValue(obj, value) {
    for (const key in obj) {
        if (obj[key] === value) {
            return key
        }
    }
    return String(value)
}

// id: 'item-02',
//         category: 'category-02',
//         subcategory: 'subcategory-02',
//         value: 66.66,
//         createdAt: 2023-12-14T11:42:20.061Z

const CollectiblesTable = ({ collectibles }) => {
    if (collectibles.length === 0) return <div>No collectibles found.</div>
    return (
        <Table celled padded>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>Subcategory</Table.HeaderCell>
                    <Table.HeaderCell>Value</Table.HeaderCell>
                    <Table.HeaderCell>Date</    Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {collectibles.map((coll, index) => {
                    // console.log('types.actionType', event, types.actionType)
                    return (
                        <Table.Row key={index}>
                            <Table.Cell>{coll.id}</Table.Cell>
                            <Table.Cell>{coll.category}</Table.Cell>
                            <Table.Cell>{coll.subcategory}</Table.Cell>
                            <Table.Cell>{coll.value}</Table.Cell>
                            <Table.Cell>{moment(coll.createdAt).format('lll')}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}

export default CollectiblesTable
