import { apiServer } from '../settings'
import { getToken } from './token'
import createLog from '../utils/create-log'

const setAuthorization = (userGuid, userAuthorization, game, guid, value, done) => {
	if (!userGuid) return done('missing userGuid')
	if (!userAuthorization) return done('missing userAuthorization')
	if (!game) return done('missing game')
	if (!guid) return done('missing guid')
	if (!value) return done('missing value')

	const headers = new Headers({
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + getToken(),
	})

	const options = {
		method: 'POST',
		headers,
		mode: 'cors',
		cache: 'default',
		body: JSON.stringify({
			game,
			guid,
			value,
			log: createLog(userGuid, userAuthorization, game, `set user auth ${guid} ${value}`),
		}),
	}

	const url = `${apiServer}/user-authorization`

	// console.log('API',game, guid, value)
	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.data.player)
			if (!response.success) return done('response without success true|false')
		})
}

export default setAuthorization
