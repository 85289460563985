import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { getGroupByShortId } from '../api'

import GroupInfoAndCommands from './GroupInfoAndCommands'
import ErrorMessage from '../ErrorMessage'

import { Context } from '../Context'

const GroupInfoLoaderByShortId = () => {
	const { shortid } = useParams()
	const { game, group, setGroup } = useContext(Context)
	const [errorMsg, setErrorMsg] = useState(null)
	const isGroup = !!group

	useEffect(() => {
		setGroup(null)
		getGroupByShortId(game, shortid, (err, group) => {
			if (err) return setErrorMsg(err)
			setGroup(group)
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game, shortid])

	// if (isGroup) return 'TODO VIEW - group found !!!!'
	if (isGroup) return <GroupInfoAndCommands />
	if (errorMsg) return <ErrorMessage title="Error" body={errorMsg} />
	return null
}

export default GroupInfoLoaderByShortId
