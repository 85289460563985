import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { getGroupScoreLevelCounters } from '../api'

import GroupScoreLevelCounters from './GroupScoreLevelCounters'
import ErrorMessage from '../ErrorMessage'
import Loader from '../Loader'

import { Context } from '../Context'

const GroupScoreLevelCountersLoader = () => {
	const { shortId } = useParams()
	const { game } = useContext(Context)
	const [errorMsg, setErrorMsg] = useState(null)
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState(null)
	// const isPlayer = !!player

	useEffect(() => {
		// setPlayer(null)
		setLoading(true)
		getGroupScoreLevelCounters(game, shortId, (err, data) => {
			setLoading(false)
			if (err) return setErrorMsg(err)
			// setPlayer(player)
		console.log('****',data)
			setData(data)
			
		})
		// return () => setPlayer(null)
	}, [game, shortId])

	// console.log(data)
	if (loading) return <Loader />
	if (data) return <GroupScoreLevelCounters shortId={shortId} levelInfo={data.levelInfo} scores={data.scores} counters={data.counters} trophies={data.trophies}/>
	if (errorMsg) return <ErrorMessage title="Error" body={errorMsg} />
	return null
}

export default GroupScoreLevelCountersLoader
