import React, { useState, useEffect } from 'react'
import { Context } from '../../Context'
import { Header, Container, List, Loader } from 'semantic-ui-react'
import { getScoreEvents } from '../../api'
import ScoreTable from './ScoreTable'
import SweetPagination from 'sweetpagination'

function ScoreEvents() {
  const { game, player } = React.useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [events, setEvents] = useState([])
  const [types, setTypes] = useState({})

  // PAGINATION
  const [currentPageData, setCurrentPageData] = useState([]);

  useEffect(() => {
      setIsLoading(true)
      getScoreEvents(game, player.guid, (err, data) => {
          // console.log(err, data)
          setIsLoading(false)
          data.events.sort((a, b) => b.timestamp - a.timestamp)
          setEvents(data.events)
          setTypes(data.types)
      })
  }, [])

  if (!player) return null

//   console.log('events', events)
//   console.log('currentPageData', currentPageData)
  return (
      <Container>
          <Header>Score Events</Header>
          {isLoading ? (
              <Loader />
          ) : (
              <div>
                  <ScoreTable events={currentPageData} types={types} />
                  <SweetPagination
                      currentPageData={setCurrentPageData}
                      getData={events}
                      dataPerPage={30}
                      navigation={true}
                  />
              </div>
          )}
      </Container>
  )
}
export default ScoreEvents