import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { List, Header, Accordion, Icon, Button } from 'semantic-ui-react'

const Item = ({ isActive, handleClick, guid, users, count, history }) => {
	return (
		<div>
			<Accordion.Title active={isActive} onClick={handleClick}>
				<Icon name="user outline" />
				{`${guid} - [${count} guids]`}
				<Button
					size="mini"
					primary
					floated="right"
					onClick={event => {
						event.stopPropagation()
						history.push(`/user/guid/${guid}`)
					}}>
					Query User
				</Button>
			</Accordion.Title>
			<Accordion.Content active={isActive}>
				<List>
					{users &&
						users.map((element, i) => (
							<List.Item key={i}>
								<Icon name="user" />
								<List.Content>
									<List.Header>
										<Link to={`/user/guid/${element.guid}`}>{element.guid}</Link>
									</List.Header>
								</List.Content>
							</List.Item>
						))}
				</List>
			</Accordion.Content>
		</div>
	)
}

// result è un array di oggetti
/*
	{guid, users: [{guid, createdAt}], count}
*/
const BanList = ({ title, result, history }) => {
	const [activeIndex, setActiveIndex] = useState(-1)

	const handleClick = index => {
		setActiveIndex(activeIndex === index ? -1 : index)
	}


	return (
		<div>
			<Header>Stats for {title}</Header>
			<Accordion styled>
				{result.map((el, key) => (
					<Item
						key={key}
						isActive={activeIndex === key}
						guid={el.guid}
						users={el.users}
						count={el.count}
						handleClick={_ => handleClick(key)}
						history = {history}
					/>
				))}
			</Accordion>
		</div>
	)
}

export default withRouter(BanList)
