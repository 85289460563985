import React, { useState } from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'

const ModalConfirm = props => {
    const [isOpen, setOpen] = useState(true)

    return (
        <Modal open={isOpen} size="small">
            <Header icon="info" content={props.text} />
            <Modal.Content>
                <p>{props.text}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color="red"
                    onClick={() => {
                        setOpen(false)
                        props.callbackNo()
                    }}>
                    No
                </Button>
                <Button
                    color="green"
                    onClick={() => {
                        setOpen(false)
                        props.callbackYes()
                    }}>
                    Yes
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ModalConfirm
