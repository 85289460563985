import { apiServer } from '../settings'
import {getToken} from './token'

const getGroupsByNick = (game, nickname, insensitive, contains, skip, limit, done) => {
	if (!game) return done('missing game')
	if (!nickname) return done('missing nickname')
	if (typeof insensitive !== 'boolean') return done('insensitive is not a boolean')
	if (typeof contains !== 'boolean') return done('contains is not a boolean')
	if (!Number.isInteger(parseInt(skip))) return done('skip is not a number or missing')
	if (!Number.isInteger(parseInt(limit))) return done('limit is not a number or missing')

	const headers = new Headers({'Authorization': 'Bearer ' + getToken()})

	const options = { method: 'GET', headers, mode: 'cors', cache: 'default' }

	const url = `${apiServer}/groups-by-nick?game=${game}&nickname=${nickname}&insensitive=${insensitive}&contains=${contains}&limit=${limit}&skip=${skip}`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.data)
			if (!response.success) return done('response without success true|false')
		})
}

export default getGroupsByNick
