import React from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts'

// const data = [
//     { timestamp: 1609459200000, value: 100 },
//     { timestamp: 1609545600000, value: 150 },
//     { timestamp: 1609718400000, value: 200 },
//     { timestamp: 1609804800000, value: 120 },
//     // ... aggiungi altri dati temporali non equispaziati
// ]

const getCurrencyName = currencyType => {
    if (currencyType === 10) return 'SFT'
    if (currencyType === 20) return 'HRD'
    return 'Unknown'
}
function processData(data) {
    const res = []

    data.forEach(elemento => {
        const { currencyType, timestamp, totalAmount } = elemento
        let currencyName = getCurrencyName(currencyType)

        const timestampMs = new Date(timestamp).getTime()

        const item = { timestamp: timestampMs }
        item[currencyName] = totalAmount
        // const randomValue = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000
        // item['HRD'] = randomValue
        res.push(item)
    })

    return res
}
// function processData(data) {
//     const res = {};

//     data.forEach((elemento) => {
//       const { currencyType, timestamp, totalAmount } = elemento;
//         // let currencyName = getCurrencyName(currencyType)
//             if (!res[currencyType]) {
//         res[currencyType] = [];
//       }

//       const timestampMs = new Date(timestamp).getTime();

//       res[currencyType].push({ timestamp: timestampMs, value: totalAmount });
//     });

//     return res;
//   }

const CurrencyGraph = ({ data, select }) => {
    const processed = processData(data)
    // console.log('processed', processed)
    // console.log('A',  processed.filter(i => i.A))
    // console.log('B', processed.filter(i => i.B))
    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={processed}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="timestamp"
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    tickFormatter={unixTime => new Date(unixTime).toLocaleString()}
                />
                <YAxis domain={['dataMin', 'dataMax']} />
                <Tooltip labelFormatter={value => new Date(value).toLocaleString()} />
                <Legend />
                <Line
                    type="monotone"
                    dataKey={select}
                    stroke="#8884d8"
                    strokeWidth={2}
                    dot={{ stroke: '#8884d8', strokeWidth: 1 }}
                />
                {/* <Line
                    type="monotone"
                    dataKey="B"
                    stroke="green"
                    strokeWidth={2}
                    dot={{ stroke: '#8884d8', strokeWidth: 1 }}
                /> */}
            </LineChart>
        </ResponsiveContainer>
    )
}

export default CurrencyGraph
