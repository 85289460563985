import React from 'react'

import { Dimmer, Loader } from 'semantic-ui-react'

const MyLoader = props => (
	<Dimmer style={props.style} active>
		<Loader>Loading</Loader>
	</Dimmer>
)

export default MyLoader
