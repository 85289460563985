import React, { useState, useEffect } from 'react'
import { Context } from '../../Context'
import { Header, Container, List, Loader } from 'semantic-ui-react'
import { getCurrencyEvents } from '../../api'
import BalanceTable from './BalanceTable'
import CurrencyGraph from './CurrencyGraph'
import SweetPagination from 'sweetpagination'

function UserBalance() {
    const { game, player } = React.useContext(Context)
    const [isLoading, setIsLoading] = useState(false)
    const [events, setEvents] = useState([])
    const [types, setTypes] = useState({})

    // PAGINATION
    // const [currentPageData, setCurrentPageData] = useState(new Array(2).fill());
    const [currentPageData, setCurrentPageData] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        getCurrencyEvents(game, player.guid, (err, data) => {
            // console.log('****', err, data)
            setIsLoading(false)
            data.events.sort((a, b) => b.timestamp - a.timestamp)
            setEvents(data.events)
            setTypes(data.types)
        })
    }, [])

    if (!player) return null
    let { currencies } = player
    // console.log('currencites', currencies)
    currencies = Object.values(currencies)
    // console.log('currencites', currencies)
    // console.log('events', events)
    // console.log('currentPageData', currentPageData)
    return (
        <Container>
            <Header>User Balance</Header>
            <CurrencyGraph data={events} select='SFT'/>
            <CurrencyGraph data={events} select='HRD'/>
            <List divided relaxed verticalAlign="middle">
                {currencies.length === 0 && <div>No currencies found.</div>}
                {currencies.map(currency => (
                    <List.Item key={currency.type} style={{ marginTop: '10px' }}>
                        <List.Content>
                            <List.Header>{currency.type}</List.Header>
                            <List.Description style={{ marginTop: '5px' }}>
                                {currency.balance}
                            </List.Description>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
            <Header>Currency Events</Header>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <BalanceTable events={currentPageData} types={types} />
                    <SweetPagination
                        currentPageData={setCurrentPageData}
                        getData={events}
                        dataPerPage={30}
                        navigation={true}
                    />
                </div>
            )}
        </Container>
    )
}
export default UserBalance
