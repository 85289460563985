import React, { useContext } from 'react'

import { Link } from 'react-router-dom'

import { Header, List, Divider, Segment, Image } from 'semantic-ui-react'

import { Context } from '../../Context'

import moment from 'moment'

const BanItem = ({ guid, createdAt, payload, type }) => {
	const created = moment(createdAt).format('lll')
	return (
		<List.Item>
			<List.Content>
				<List.Header>
					<Link to={`/user/guid/${guid}`}>{guid}</Link>
				</List.Header>
				{created}
			</List.Content>
			<List.Content floated='right'>
				{type === 'picture' ? <Image src={payload} size='tiny' /> : null}
				{type === 'nickname' ? payload : null}
				{type === 'chat' ? <div style={{maxHeight: '150px', overflow: 'auto'}} dangerouslySetInnerHTML={{__html: payload}} /> : null}
			</List.Content>
		</List.Item>
	)
}

const BanItemList = ({ values, type }) => {
	if (!values || values.length === 0) return 'No values to display'

	return (
		<List divided verticalAlign='middle'>
			{values.map((value, index) => (
				<BanItem key={index} {...value} type={type} />
			))}
		</List>
	)
}

const BanPanelChild = ({ title, values, type }) => {
	return (
		<Segment>
			<Header>{title}</Header>
			<BanItemList values={values} type={type} />
		</Segment>
	)
}

const BanInfoParent = ({ title, givenTo, receivedBy, type }) => {
	return (
		<Segment inverted color='grey'>
			<Header>{title}</Header>
			<Divider />
			<BanPanelChild title='Received By' values={receivedBy} type={type}/>
			<Divider />
			<BanPanelChild title='Given To' values={givenTo} type={type} />
		</Segment>
	)
}

const SoftBanList = () => {
	const { player } = useContext(Context)
	if (!player) return 'No Player Set'
	if (!player.softBan) return 'No Soft Ban Info'

	const overridePolicyPicture = player.softBan.picture && player.softBan.picture.receivedByOverride ? player.softBan.picture.receivedByOverride : null
	const overridePolicyChat = player.softBan.chat && player.softBan.chat.receivedByOverride ? player.softBan.chat.receivedByOverride : null
	const overridePolicyNickname = player.softBan.nickname && player.softBan.nickname.receivedByOverride ? player.softBan.nickname.receivedByOverride : null
	
	return (
		<div>
			<Header>Soft Ban Info</Header>
			<Divider />
			<BanInfoParent
				title={overridePolicyPicture ? `Picture (${overridePolicyPicture})` : 'Picture'}
				type='picture'
				givenTo={player.softBan.picture ? player.softBan.picture.givenTo : null}
				receivedBy={player.softBan.picture ? player.softBan.picture.receivedBy : null}
			/>
			<Divider />
			<BanInfoParent
			title={overridePolicyChat ? `Chat (${overridePolicyChat})` : 'Chat'}
				type='chat'
				givenTo={player.softBan.chat ? player.softBan.chat.givenTo : null}
				receivedBy={player.softBan.chat ? player.softBan.chat.receivedBy : null}
			/>
			<Divider />
			<BanInfoParent
			title={overridePolicyNickname ? `Nickname (${overridePolicyNickname})` : 'Nickname'}
				type='nickname'
				givenTo={player.softBan.nickname ? player.softBan.nickname.givenTo : null}
				receivedBy={player.softBan.nickname ? player.softBan.nickname.receivedBy : null}
			/>
		</div>
	)
}

export default SoftBanList
