import async from 'async'
import getUserByGuid from './get-user-by-guid'

const getUsersByGuid = (game, guids, done) => {
	if (!game) return done('missing game')
	if (!guids) return done('missing guids')

	const iteratee = (guid, next) => {
		getUserByGuid(game, guid, (err, res) => {
			// don't end the async operation for a single error
			if (err) return next(null, {guid, err})
			next(null, res)
		})
	}
	async.map(guids, iteratee, (err, result) => {		
		if (err) return done(err)
		done(null, result)
	})
}


export default getUsersByGuid
