import React, { useContext } from 'react'

import { Header, List } from 'semantic-ui-react'

import { Context } from '../../Context'

import moment from 'moment'

// renderizza anche solo guid (ad esempio quando vengono passate le relationships)
const Item = ({ type, createdAt, lastAccessedAt }) => {
	// console.log(externalId)
	const created = moment(createdAt).format('lll')
	const last = moment(lastAccessedAt).format('lll')
	return (
		<List.Item>
			<List.Content>
				<List.Header>
					<b>Type:</b> {type}
				</List.Header>
				<List.Description>
					<b>Created:</b> {created}
				</List.Description>
				<List.Description>
					<b>Last Access:</b> {last}
				</List.Description>
			</List.Content>
		</List.Item>
	)
}

const PlatformList = () => {
	const { player } = useContext(Context)
	if (!player) return 'No Player Set'
	if (!player.platforms || player.platforms.length === 0) return 'No Platforms'
	return (
		<div>
			<Header>Platforms</Header>
			<List celled>
				{player.platforms.map((platform, index) => (
					<Item key={index} {...platform} />
				))}
			</List>
		</div>
	)
}

export default PlatformList
