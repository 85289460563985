import React, { useState, useContext, createContext } from 'react'
import * as api from '../api'

const AuthorizationContext = createContext()

const useAuthorization = () => {
	return useContext(AuthorizationContext)
}

// https://usehooks.com/useAuth/
function useProvideAuth() {
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const login = (game, email, password) => {
		setError(null)
		setLoading(true)
		api.login(game, email, password, (err, user) => {
			setLoading(false)
			if (err) return setError(err)
			setUser(user)
		})
	}

	const isUserAdmin = user && user.authorization === 'admin'

	const isUserModerator = user && user.authorization === 'moderator'

	//data and methods
	return {
		user,
		loading,
		error,
		login,
		isUserAdmin,
		isUserModerator,
	}
}

const AuthorizationProvider = ({ children }) => {
	const auth = useProvideAuth()
	return <AuthorizationContext.Provider value={auth}>{children}</AuthorizationContext.Provider>
}

export { AuthorizationProvider, useAuthorization }
