import React from 'react'
import { Grid, Button } from 'semantic-ui-react'

// import { useAuthorization } from '../Contexts/AuthorizationContext'

const Commands = ({
	onDeleteHandler,
	onBanHandler,
	isBanned,
}) => {
	// rifertito alla persona loggata su simon
	// const { isUserAdmin } = useAuthorization()

	return (
		<Grid columns={2}>
			<Grid.Column>
				<Button fluid onClick={onDeleteHandler}>
					Delete Group
				</Button>
			</Grid.Column>
			<Grid.Column>
				<Button fluid onClick={onBanHandler}>
					{isBanned ? 'Remove Ban' : 'Give Ban'}
				</Button>
			</Grid.Column>
		</Grid>
	)
}

export default Commands
