import React, { useContext } from 'react'

import { Header, List } from 'semantic-ui-react'

import { Context } from '../../Context'

const Item = ({ fcm }) => {
	return (
		<List.Item>
			<List.Content>
				<List.Header>{fcm}</List.Header>
			</List.Content>
		</List.Item>
	)
}

const FcmList = () => {
	const { player } = useContext(Context)
	if (!player) return 'No Player Set'
	if (!player.fcm || player.fcm.length === 0) return 'No FCM'
	return (
		<div>
			<Header>Fcm</Header>
			<List celled>
				{player.fcm.map((_fcm, index) => (
					<Item key={index} fcm={_fcm} />
				))}
			</List>
		</div>
	)
}

export default FcmList
