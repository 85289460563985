import React, { useEffect, useState, useContext } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { getGroupsByNick } from '../api'
import fromStringToBoolean from '../utils/from-string-to-boolean'

import GroupInfoAndCommands from '../Groups/GroupInfoAndCommands'
import GroupList from '../Groups/GroupList'
import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'
import Paginator from '../Paginator'

import { Context } from '../Context'

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

const GroupInfoLoaderByNick = () => {
	const { nick } = useParams()
	const query = useQuery()
	const insensitive = query.get('insensitive')
	const contains = query.get('contains')
	const skip = query.get('skip')
	const limit = query.get('limit')

	const { game, group, setGroup } = useContext(Context)
	const [errorMsg, setErrorMsg] = useState(null)
	const [groups, setGroups] = useState([])
	const [count, setCount] = useState(0)
	const [loading, setLoading] = useState(false)
	const isGroup = !!group

	useEffect(() => {
		let insensitiveBool = fromStringToBoolean(insensitive)
		let containsBool = fromStringToBoolean(contains)
		if (insensitiveBool instanceof Error) {
			return setErrorMsg(insensitiveBool.message)
		}
		if (containsBool instanceof Error) {
			return setErrorMsg(containsBool.message)
		}
		if (!Number.isInteger(parseInt(skip))) {
			return setErrorMsg('skip missing or not a number')
		}
		if (!Number.isInteger(parseInt(limit))) {
			return setErrorMsg('limit missing or not a number')
		}

		setGroup(null)
		setLoading(true)
		getGroupsByNick(game, nick, insensitiveBool, containsBool, skip, limit, (err, data) => {
			setLoading(false)
			// console.log('data', data)
			// console.log('DONE', players && players.length)
			if (err) return setErrorMsg(err)

			const { count, groups } = data
			// console.log('xxxxxxx', count, groups)
			switch (groups.length) {
				case 0:
					setErrorMsg('No group found')
					setGroup(null)
					setGroups([])
					setCount(count)
					break
				case 1:
					setErrorMsg(null)
					setGroup(groups[0])
					setGroups([])
					setCount(count)
					break
				default:
					setErrorMsg(null)
					setGroup(null)
					setGroups(groups)
					setCount(count)
					break
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game, nick, insensitive, contains, skip, limit])

	if (isGroup) return <GroupInfoAndCommands />
	if (errorMsg) return <ErrorMessage title="Error" body={errorMsg}/>
	if (loading) return  <Loader />
	if (groups.length) {
		const GROUP_PER_PAGE = groups.length
		const totalPages = parseInt(count / GROUP_PER_PAGE + 1)
		return (
			<div>
				<GroupList list={groups} count={count}/>
				<Paginator totalPages={totalPages} entity='group'/>
			</div>
		)
	}

	return null
}

export default GroupInfoLoaderByNick
