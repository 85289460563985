import { apiServer } from '../settings'
import {setToken} from './token'

const login = (game, email, password, done) => {
	// console.log(game, email, password)
	if (!game) return done('missing game')
	if (!email) return done('missing email')
	if (!password) return done('missing password')

	const headers = new Headers({
			'Content-Type': 'application/json',
		})

	const options = {
		method: 'POST',
		headers,
		mode: 'cors',
		cache: 'default',
		body: JSON.stringify({ game, email, password }),
	}

	const url = `${apiServer}/login`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			
			if (response.success === true) {
				setToken(response.data.token)
				return done(null, response.data.player)
			}
			if (!response.success) return done('response without success true|false')
		})
}

export default login
