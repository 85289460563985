import React, { useState, useContext } from 'react'
import { Header, Form, Button, List, Segment, Message, Label, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Loader from '../Loader'
import { createGroup } from '../api'
import { Context } from '../Context'

const MemberItem = ({guid, onRemove}) => {
	return (
		<Label color="yellow">
			<Icon name="user" />
			{guid}
			<Label.Detail
				as="a"
				onClick={()=>onRemove(guid)}>
				Remove
			</Label.Detail>
		</Label>
	)
	// return <div>{guid}xxx</div>
}

const GroupCreate = ({ history }) => {
	// const { user } = useAuthorization()
	const { game } = useContext(Context)

	const [errorMsg, setErrorMsg] = useState(null)
	const [success, setSuccess] = useState(false)
	const [shortId, setShortId] = useState(null)
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState('')
	const [adminGuid, setAdminGuid] = useState('')
	const [admins, setAdmins] = useState([])
	const [memberGuid, setMemberGuid] = useState('')
	const [members, setMembers] = useState([])

	const resetFormFields = () => {
		setName('')
		setAdmins([])
		setMembers([])
	}

	const handleOnRemoveAdmin = (guid) => {
		const index = admins.indexOf(guid)
		admins.splice(index, 1)
		setAdmins([...admins])
	}

	const handleOnRemoveMember = (guid) => {
		const index = members.indexOf(guid)
		members.splice(index, 1)
		setMembers([...members])
	}

	const handleName = (e, { name, value }) => setName(value)
	const handleAdminGuid = (e, { name, value }) => {
		setAdminGuid(value)
	}
	const handleAdmins = () => {
		if (!adminGuid) return
		setAdmins([...admins, adminGuid])
		setAdminGuid('')
	}

	const handleMemberGuid = (e, { name, value }) => {
		setMemberGuid(value)
	}
	const handleMembers = () => {
		if (!memberGuid) return
		setMembers([...members, memberGuid])
		setMemberGuid('')
	}

	const handleSubmit = e => {
		e.preventDefault()
		setLoading(true)

		const membersAndAdmins = [
			...admins.map(guid => ({ guid, role: 'admin' })),
			...members.map(guid => ({ guid, role: 'member' })),
		]

		const data = {
			game,
			members: membersAndAdmins, //si-players vuole un array di membri
			name,
		}
		createGroup(data, (err, group) => {
			setLoading(false)
			if (err) {
				setSuccess(false)
				setShortId(null)
				setErrorMsg(err)
				return
			}
			resetFormFields()
			setErrorMsg(null)
			setSuccess(true)
			setShortId(group.shortId)
		})
	}

	return (
		<Segment>
			{loading && <Loader style={{ height: '150%' }} />}
			<Form onSubmit={handleSubmit} success={success} error={errorMsg !== null}>
				<Header>Create Group</Header>
				<Form.Input
					label="Group name"
					placeholder="group name"
					name="name"
					value={name}
					onChange={handleName}
				/>

				<Form.Input
					label="Admin Guid"
					placeholder="Admin guid"
					name="adminGuid"
					value={adminGuid}
					onChange={handleAdminGuid}
					action={
						<Button type="button" onClick={handleAdmins}>
							Add Admin
						</Button>
					}></Form.Input>
				{admins.length !== 0 && (
					<List>
						{admins.map(guid => (
							<List.Item key={guid}
								content=<MemberItem
									guid={guid}
									onRemove={handleOnRemoveAdmin}/>
							/>
						))}
					</List>
				)}

				<Form.Input
					label="Member Guid"
					placeholder="Member guid"
					value={memberGuid}
					onChange={handleMemberGuid}
					action={
						<Button type="button" onClick={handleMembers}>
							Add Member
						</Button>
					}></Form.Input>
				{members.length !== 0 && (
					<List>
						{members.map(guid => (
							<List.Item key={guid}
								content=<MemberItem
									guid={guid}
									onRemove={handleOnRemoveMember}/>
							/>
						))}
					</List>
				)}

				<Form.Button content="Create" />

				<Message
					success
					header="Group Created"
					content=<Link to={`/group/shortid/${shortId}`}>
						The group has shortId {shortId}
					</Link>
				/>
				<Message error header="Error" content={errorMsg} />
			</Form>
		</Segment>
	)
}

export default withRouter(GroupCreate)
