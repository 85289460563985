import React, { useState, useEffect } from 'react'
import { Context } from '../../Context'
import { Header, Container, Loader } from 'semantic-ui-react'
import { getCollectibles } from '../../api'
import CollectiblesTable from './CollectiblesTable'
import SweetPagination from 'sweetpagination'

function UserCollectibles() {
    const { game, player } = React.useContext(Context)
    const [isLoading, setIsLoading] = useState(false)
    const [collectibles, setCollectibles] = useState([])

    // PAGINATION
    // const [currentPageData, setCurrentPageData] = useState(new Array(2).fill());
    const [currentPageData, setCurrentPageData] = useState([]);

    const fakeCollectiblesToRemove = [
        {
          id: 'item-01',
          category: 'category-01',
          subcategory: 'subcategory-01',
          value: 66.66,
          createdAt: '2023-12-14T11:42:20.051Z'
        },
        {
          id: 'item-02',
          category: 'category-02',
          subcategory: 'subcategory-02',
          value: 66.66,
          createdAt: '2023-12-14T11:42:20.061Z'
        }
      ]
    useEffect(() => {
        setIsLoading(true)
        getCollectibles(game, player.guid, (err, data) => {
            console.log('****TODO - REAL DATA ARE NOT IMPLEMENTED****', err, data)
            setIsLoading(false)
            setCollectibles(fakeCollectiblesToRemove)
            // data.events.sort((a, b) => b.createdAt - a.createdAt)
            // setEvents(data.events)
            // setTypes(data.types)
        })
    }, [])

    if (!player) return null

    // console.log('events', events)
    // console.log('currentPageData', currentPageData)
    return (
        <Container>
            <Header>User Collectibles</Header>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <CollectiblesTable collectibles={collectibles} />
                    <SweetPagination
                        currentPageData={setCurrentPageData}
                        getData={collectibles}
                        dataPerPage={30}
                        navigation={true}
                    />
                </div>
            )}
        </Container>
    )
}
export default UserCollectibles
