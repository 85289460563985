import React from 'react'

const BanStatsContext = React.createContext()

const useBanStatsContext = () => {
	const context = React.useContext(BanStatsContext)
	if (!context) {
		throw new Error(`useCount must be used within a CountProvider`)
	}
	return context
}

const BanStatsProvider = props => {
	const [resultFromApi, setResultFromApi] = React.useState(null)
	const value = React.useMemo(() => [resultFromApi, setResultFromApi], [resultFromApi])
	return <BanStatsContext.Provider value={value} {...props} />
}

export  { BanStatsProvider, useBanStatsContext }