import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Container, Divider, Header, Button, Tab } from 'semantic-ui-react'

import { Context } from './Context'

import GameSelector from './GameSelector'
import UserSearch from './UserSearch'

import GroupSearch from './Groups/GroupSearch'

import UserList from './UserList'
import AuthMechList from './AuthMechList'
import PlatformList from './user-info/subs/PlatformList'
import FcmList from './user-info/subs/FcmList'
import NotificationList from './user-info/subs/NotificationList'
import SoftBanList from './user-info/subs/SoftBanList'
// Score events e' una view a parte, mentre Currency Events è dentro UserBalance
import UserBalance from './user-info/subs/UserBalance'
import UserCollectibles from './user-info/subs/UserCollectibles'
import UserConversations from './user-info/subs/UserConversations'
import UserInstallations from './user-info/subs/UserInstallations'


import UserGroupListLoader from './user-info/subs/UserGroupListLoader'
import ScoreLevelCountersLoader from './user-info/subs/ScoreLevelCountersLoader'
// Score events e' una view a parte, mentre Currency Events è dentro UserBalance
import ScoreEvents from './user-info/subs/ScoreEvents'

import UserInfoLoaderByGuid from './UserInfoLoaderByGuid'
import UserInfoLoaderByShortId from './UserInfoLoaderByShortId'
import UserInfoLoaderByEmail from './UserInfoLoaderByEmail'
import UserInfoLoaderByNick from './UserInfoLoaderByNick'

import GroupInfoLoaderByShortId from './Groups/GroupInfoLoaderByShortId'
import GroupInfoLoaderByNick from './Groups/GroupInfoLoaderByNick'
import GroupScoreLevelCountersLoader from './Groups/GroupScoreLevelCountersLoader'
import GroupCreate from './Groups/GroupCreate'
import GroupMessages from './Groups/GroupMessages'

import BanStats from './ban-stats/BanStats'
import BannedUsers from './banned-users/BannedUsers'

import Bots from './bots/Bots'
import Jackpots from './jackpots/Jackpots'

const Home = ({ history }) => {
    const { game /*, mainTabActiveIndex */ } = React.useContext(Context)
    const isSpecificGame = game && game !== 'ALL'

    const panes = [
        {
            menuItem: 'Users',
            pane: (
                <Tab.Pane key="users">
                    <UserSearch />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Groups',
            pane: (
                <Tab.Pane key="groups">
                    <GroupSearch />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Bans',
            pane: (
                <Tab.Pane key="bans">
                    <Button as={Link} disabled={!isSpecificGame} to="/ban-stats">
                        Ban Reports
                    </Button>
                    <Button as={Link} disabled={!isSpecificGame} to="/banned-users">
                        Banned Users
                    </Button>
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Bots',
            pane: (
                <Tab.Pane key="bots">
                    <Button as={Link} disabled={!isSpecificGame} to="/bots">
                        Fetch Bots
                    </Button>
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Jackpots',
            pane: (
                <Tab.Pane key="jackpots">
                    <Button as={Link} disabled={!isSpecificGame} to="/jackpots">
                        Fetch Jackpots
                    </Button>
                </Tab.Pane>
            ),
        },
    ]

    return (
        <Container>
            <div />
            <Button.Group basic size="big">
                <Button as={Link} to="/" icon="home" />
                <Button onClick={history.goBack} icon="arrow alternate circle left" />
            </Button.Group>

            <Header size="large">{game ? `Game ${game}` : 'Choose a game'}</Header>

            <Divider />
            <GameSelector />
            <Divider />
            <Tab
                /*activeIndex={mainTabActiveIndex}*/ panes={panes}
                renderActiveOnly={false}
                style={{ marginBottom: '25px' }}
            />
            {
                <Switch>
                    <Route path="/user/guid/:guid">
                        <UserInfoLoaderByGuid />
                    </Route>
                    <Route path="/user/shortid/:shortid">
                        <UserInfoLoaderByShortId />
                    </Route>
                    <Route path="/user/email/:email">
                        <UserInfoLoaderByEmail />
                    </Route>
                    <Route path="/user/nick/:nick">
                        <UserInfoLoaderByNick />
                    </Route>
                    <Route path="/user/authmechs">
                        <AuthMechList />
                    </Route>
                    <Route path="/user/platforms">
                        <PlatformList />
                    </Route>
                    <Route path="/user/fcm">
                        <FcmList />
                    </Route>
                    <Route path="/user/score-level/:guid">
                        <ScoreLevelCountersLoader />
                    </Route>
                    <Route path="/user/score-events/:guid">
                        <ScoreEvents/>
                    </Route>
                    <Route path="/user/notifications">
                        <NotificationList />
                    </Route>
                    <Route path="/user/soft-bans">
                        <SoftBanList />
                    </Route>
                    <Route path="/user/groups">
                        <UserGroupListLoader />
                    </Route>
                    <Route path="/user/balance">
                        <UserBalance />
                    </Route>
                    <Route path="/user/collectibles">
                        <UserCollectibles />
                    </Route>
                    <Route path="/user/conversations">
                        <UserConversations />
                    </Route>
                    <Route path="/user/installations">
                        <UserInstallations />
                    </Route>
                    <Route path="/users">
                        <UserList list={[]} />
                    </Route>
                    <Route path="/ban-stats">
                        <BanStats />
                    </Route>
                    <Route path="/banned-users">
                        <BannedUsers />
                    </Route>
                    <Route path="/group/shortid/:shortid">
                        <GroupInfoLoaderByShortId />
                    </Route>
                    <Route path="/group/nick/:nick">
                        <GroupInfoLoaderByNick />
                    </Route>
                    <Route path="/group/score-level/:shortId">
                        <GroupScoreLevelCountersLoader />
                    </Route>
                    <Route path="/group/create">
                        <GroupCreate />
                    </Route>
                    <Route path="/group/messages">
                        <GroupMessages />
                    </Route>
                    <Route path="/bots">
                        <Bots />
                    </Route>
                    <Route path="/jackpots">
                        <Jackpots />
                    </Route>
                </Switch>
            }
        </Container>
    )
}

export default withRouter(Home)
