import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { getUserByEmail } from './api'

import UserInfoAndCommands from './user-info/UserInfoAndCommands'
import UserList from './UserList'
import Loader from './Loader'
import ErrorMessage from './ErrorMessage'

import { Context } from './Context'

const getAllPlayers = data => {
	// data.filter(gameAndPlayers => gameAndPlayers.players.map(player=>player.guid))
	const allPlayers = []
	data.forEach(gameAndPlayers => {
		if (gameAndPlayers.players.length !== 0) {
			gameAndPlayers.players.forEach(player => {
				player.game = gameAndPlayers.game
				allPlayers.push(player)
			})
		}
	})
	return allPlayers
}

const UserInfoLoaderByEmail = () => {
	const { email } = useParams()
	const { game, player, setPlayer } = useContext(Context)
	const [players, setPlayers] = useState([])
	const [count, setCount] = useState(0)
	const [loading, setLoading] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const isPlayer = !!player

	useEffect(() => {
		setPlayer(null)
		setLoading(true)
		getUserByEmail(game, email, (err, data) => {
			setLoading(false)
			if (err) {
				setErrorMsg(err)
				setPlayer(null)
				return
			}
			const allPlayers = getAllPlayers(data)
			const k = allPlayers.length
			switch (k) {
				case 0:
					setErrorMsg('No players found')
					setPlayer(null)
					setPlayers([])
					setCount(k)
					break
				case 1:
					setErrorMsg(null)
					// allPlayers[0].player.game = allPlayers[0].game
					setPlayer(allPlayers[0])
					setPlayers([])
					setCount(k)
					break
				default:
					setErrorMsg(null)
					setPlayer(null)
					setPlayers(
						allPlayers
						// data.map(item => {
						// 	item.player.game = item.game
						// 	return item.player
						// })
					)
					setCount(k)
					break
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game, email])
	if (isPlayer) return <UserInfoAndCommands />
	if (errorMsg) return <ErrorMessage title="Error" body={errorMsg} />
	if (loading) return <Loader />
	if (players.length) {
		// const USER_PER_PAGE = 20
		// const totalPages = parseInt(count / USER_PER_PAGE + 1)
		return (
			<div>
				{/*loading && <Loader />*/}
				<UserList list={players} count={count} />
				{/*<Paginator totalPages={totalPages} />*/}
			</div>
		)
	}
	return 'enter a value'
	// return isPlayer ? <UserInfoAndCommands/> : errorMsg ? errorMsg : '[mail] enter a value'
}

export default UserInfoLoaderByEmail
