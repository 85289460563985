import React from 'react'
import { List, Segment, Image, Header } from 'semantic-ui-react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Context } from '../Context'
import moment from 'moment'

const Message = ({ guid, createdAt, text }) => {
	
	const created = moment(createdAt).format('lll')

	return (
	<List.Item>
		<Image avatar src="/images/avatars/0.jpg" />
		<List.Content>
			<List.Header>
				<Link to={`/user/guid/${guid}`}>{guid}</Link>
			</List.Header>
			<List.Description>{created} <br/><br/> {text}</List.Description>
		</List.Content>
	</List.Item>
)}

const GroupMessages = ({ history }) => {
	const { group } = React.useContext(Context)
	const { messages } = group

	if (messages.length === 0) return <Segment>No messages for this group yet.</Segment>	

	return (
		<Segment>
			<Header content="Messages"/>
			<List celled style={{ overflowY: 'scroll', height: '350px' }}>
				{messages.map((message,i) => (
					<Message key={i} {...message} />
				))}
			</List>
		</Segment>
	)
}

export default withRouter(GroupMessages)
