import React, { useState, useEffect } from 'react'
import { Table, Modal, Button, Header } from 'semantic-ui-react'
import moment from 'moment'
import { getMessagesByConvId } from '../../api'
import { Context } from '../../Context'

// CONV OBJ
// {
//     "_id": "65d7dc950f8ef30449df93bd",
//     "lastUpdatedAt": "2023-01-02T23:52:53.047Z",
//     "updatedAt": "2024-02-27T01:09:20.377Z",
//     "createdAt": "2022-12-26T14:24:44.812Z",
//     "refused": false,
//     "terminated": true,
//     "playersInfo": [
//         {
//             "guid": "72d31e5e-970d-4aeb-b407-c91638d5f08c",
//             "notReadCount": 0
//         },
//         {
//             "guid": "571de7da-0911-4d98-98a4-a2b633db0713",
//             "notReadCount": 0
//         }
//     ],
//     "lastMsgFromGuid": "72d31e5e-970d-4aeb-b407-c91638d5f08c",
//     "createdByGuid": "72d31e5e-970d-4aeb-b407-c91638d5f08c",
//     "convId": "049f9bd8-d015-45ec-a474-531b9156c293",
//     "expiresAt": "2024-08-25T00:09:20.352Z"
// }

// TODO Messages andrebbe portato fuori
// MESSAGES
// {
//     "fromGuid": "482a3b03-d32f-46c5-9b96-be1fa180213e",
//     "msgType": "text",
//     "text": "Se vuoi possiamo con",
//     "timestamp": "2024-01-26T22:14:42.512Z"
// }

const MessagesModal = ({ messages }) => {
    const [isOpen, setOpen] = useState(false)
    // trigger={<Button>View</Button>}>
    useEffect(() => {
        // Apre il modal solo se messages.length è diverso da zero
        setOpen(messages.length !== 0);
    }, [messages]);

    // console.log('messages', messages)
    return (
        <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={isOpen}>
            <Modal.Header>Messages</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {messages.map((msg, index) => (
                        <div key={index}>
                            {moment(msg.timestamp).format('lll')} - ({msg.fromGuid}) - {msg.text}
                        </div>
                    ))}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => setOpen(false)}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

const formatPlayersInfo = playersInfo => {
    return `${playersInfo[0].guid}:${playersInfo[0].notReadCount}<br/>${playersInfo[1].guid}:${playersInfo[1].notReadCount}`
}
const ConversationsTable = ({ conversations }) => {
    const { game, player } = React.useContext(Context)
    const [messages, setMessages] = useState([])
    const [error, setError] = useState("")

    const handleCellClick = convId => {
        setError("")
        getMessagesByConvId(game, convId, (error, messages) => {
            if (error) {
                console.error('Errore durante il recupero dei messaggi:', error)
                return
            }
            if (!messages) {
                setError("Nessun messaggio associato al convId")
                return;
            }
            // console.log(messages)
            messages.sort((a, b) => new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf())
            setMessages([...messages])
        })
    }

    if (conversations.length === 0) return <div>No conversations found.</div>
    return (
        <>
          {error && <Header as='h4' color='red'>{error}</Header>}
        <Table celled padded>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>ConvId</Table.HeaderCell>
                    <Table.HeaderCell>CreatedAt</Table.HeaderCell>
                    <Table.HeaderCell>LastUpdatedAt</Table.HeaderCell>
                    <Table.HeaderCell>Refused</Table.HeaderCell>
                    <Table.HeaderCell>Terminated</Table.HeaderCell>
                    <Table.HeaderCell>PlayersInfo</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {conversations.map((conv, index) => {
                    // console.log('types.actionType', event, types.actionType)
                    return (
                        <Table.Row key={index}>
                            {/* <Table.Cell>{conv.convId}</Table.Cell> */}
                            <a
                                href="#"
                                onClick={e => {
                                    e.preventDefault()
                                    handleCellClick(conv.convId)
                                }}>
                                {conv.convId}
                            </a>
                            <Table.Cell>{moment(conv.createdAt).format('lll')}</Table.Cell>
                            <Table.Cell>{moment(conv.lastUpdatedAt).format('lll')}</Table.Cell>
                            <Table.Cell>{conv.refused ? 'true' : 'false'}</Table.Cell>
                            <Table.Cell>{conv.terminated ? 'true' : 'false'}</Table.Cell>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: formatPlayersInfo(conv.playersInfo),
                                }}
                            />
                        </Table.Row>
                    )
                })}
            </Table.Body>
            <MessagesModal messages={messages} />
        </Table>
        </>
    )
}

export default ConversationsTable
