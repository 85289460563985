import React, { useEffect, useState, useContext } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { getUsersByNick } from './api'
import fromStringToBoolean from './utils/from-string-to-boolean'

import UserInfoAndCommands from './user-info/UserInfoAndCommands'
import UserList from './UserList'
import Loader from './Loader'
import ErrorMessage from './ErrorMessage'
import Paginator from './Paginator'

import { Context } from './Context'

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

const UserInfoLoaderByNick = () => {
	const { nick } = useParams()
	const query = useQuery()
	const insensitive = query.get('insensitive')
	const contains = query.get('contains')
	const skip = query.get('skip')
	const limit = query.get('limit')

	const { game, player, setPlayer } = useContext(Context)
	const [errorMsg, setErrorMsg] = useState(null)
	const [players, setPlayers] = useState([])
	const [count, setCount] = useState(0)
	const [loading, setLoading] = useState(false)
	const isPlayer = !!player

	useEffect(() => {
		let insensitiveBool = fromStringToBoolean(insensitive)
		let containsBool = fromStringToBoolean(contains)
		if (insensitiveBool instanceof Error) {
			return setErrorMsg(insensitiveBool.message)
		}
		if (containsBool instanceof Error) {
			return setErrorMsg(containsBool.message)
		}
		if (!Number.isInteger(parseInt(skip))) {
			return setErrorMsg('skip missing or not a number')
		}
		if (!Number.isInteger(parseInt(limit))) {
			return setErrorMsg('limit missing or not a number')
		}

		setPlayer(null)
		setLoading(true)
		getUsersByNick(game, nick, insensitiveBool, containsBool, skip, limit, (err, data) => {
			setLoading(false)
			// console.log('DONE', players && players.length)
			if (err) return setErrorMsg(err)

			const { count, players } = data
			// console.log(count, players)
			switch (players.length) {
				case 0:
					setErrorMsg('No players found')
					setPlayer(null)
					setPlayers([])
					setCount(count)
					break
				case 1:
					setErrorMsg(null)
					setPlayer(players[0])
					setPlayers([])
					setCount(count)
					break
				default:
					setErrorMsg(null)
					setPlayer(null)
					setPlayers(players)
					setCount(count)
					break
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game, nick, insensitive, contains, skip, limit])

	if (isPlayer) return <UserInfoAndCommands />
	if (errorMsg) return <ErrorMessage title="Error" body={errorMsg}/>
	if (loading) return  <Loader />
	if (players.length) {
		const USER_PER_PAGE = players.length
		const totalPages = parseInt(count / USER_PER_PAGE + 1)
		return (
			<div>
				{/*loading && <Loader/>*/}
				<UserList list={players} count={count}/>
				<Paginator totalPages={totalPages} entity='user'/>
			</div>
		)
	}

	return null
}

export default UserInfoLoaderByNick
