import React, { useState, useContext } from 'react'

import { withRouter } from 'react-router'
// import { Link } from 'react-router-dom'
import { Form, Button } from 'semantic-ui-react'
import { getAllBanStats } from '../api'

import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'

import { Context } from '../Context'
import {useBanStatsContext} from '../Contexts/BanStatsContext'

import BanList from './BanList'

const PIX = '5px'

const resultsOption = [
    {
        key: 5,
        text: 5,
        value: 5,
    },
    {
        key: 10,
        text: 10,
        value: 10,
    },
    {
        key: 20,
        text: 20,
        value: 20,
    },
    {
        key: 30,
        text: 30,
        value: 30,
    },
]

const dayOption = [
    {
        key: 1,
        text: 1,
        value: 1,
    },
    {
        key: 2,
        text: 2,
        value: 2,
    },
    {
        key: 3,
        text: 3,
        value: 3,
    },
    {
        key: 7,
        text: 7,
        value: 7,
    },
    {
        key: 15,
        text: 15,
        value: 15,
    },
    {
        key: 30,
        text: 30,
        value: 30,
    }
]

const BanStats = ({ history }) => {
    const { game } = useContext(Context)
    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(false)
    const [numberOfResults, setNumberOfResults] = useState(resultsOption[0].value)
    const [days, setDays] = useState(dayOption[0].value)
    // GESTIONE LOCALE
    // const [resultFromApi, setResultFromApi] = useState(null)
    const [resultFromApi, setResultFromApi] = useBanStatsContext()

    const handleNumberOfResults = (e, { value }) => setNumberOfResults(value)
    const handleDays = (e, { value }) => setDays(value)
    const handleFetch = () => {
        setLoading(true)
        getAllBanStats(game, numberOfResults, days * 24, (err, result) => {
            setLoading(false)
            if (err) return setErrorMsg(err)
            // GESTIONE LOCALE
            // setResultFromApi(fakeResultCancellare)
            setResultFromApi(result)
        })
    }

    if (errorMsg) return <ErrorMessage title="Error" body={errorMsg} />
    if (loading) return <Loader />

    return (
        <div>
            <Form>
                <Form.Group inline style={{ justifyContent: 'center' }}>
                    <div style={{ marginRight: PIX }}>Results</div>
                    <Form.Dropdown
                        button
                        value={numberOfResults}
                        options={resultsOption}
                        onChange={handleNumberOfResults}
                    />
                    <div style={{ marginRight: PIX }}>Days</div>
                    <Form.Dropdown
                        button
                        value={days}
                        options={dayOption}
                        onChange={handleDays}
                    />
                    <Button primary onClick={handleFetch}>
                        Fetch
                    </Button>
                </Form.Group>
            </Form>
            {resultFromApi && (
                <BanList title="Chat (receivers)" result={resultFromApi.chat.receivedBy} />
            )}
            <br />
            {resultFromApi && <BanList title="Chat (givers)" result={resultFromApi.chat.givenTo} />}
            <br />
            {resultFromApi && (
                <BanList title="Pix (receivers)" result={resultFromApi.picture.receivedBy} />
            )}
            <br />
            {resultFromApi && (
                <BanList title="Pix (givers)" result={resultFromApi.picture.givenTo} />
            )}
            <br />
            {resultFromApi && (
                <BanList title="Nick (receivers)" result={resultFromApi.nickname.receivedBy} />
            )}
            <br />
            {resultFromApi && (
                <BanList title="Nick (givers)" result={resultFromApi.nickname.givenTo} />
            )}
        </div>
    )
}

export default withRouter(BanStats)
