// const apiServer = process.env.NODE_ENV === 'production' ? 'http://217.61.25.144:3123': 'http://localhost:3123'
// const apiServer = process.env.NODE_ENV === 'production' ? 'http://192.168.0.121:3123': 'http://localhost:3123'

// In production si usa il dominio e porta del server static (3123), mentre in sviluppo static viene servito da create react app
//(per fare prima con npm start sulla porta 3000 e quindi qui va specificata una porta diversa per il server api)
const apiServer = process.env.NODE_ENV === 'production' ? '': 'http://localhost:3123'

const games = [
    {
        key: 'assopigliapiu',
        text: 'Assopigliapiu',
        value: 'assopigliapiu',
        image: { avatar: true, src: '/images/games/assopigliapiu/64x64.png' },
    },
    {
        key: 'briscamas',
        text: 'Briscamas',
        value: 'briscamas',
        image: { avatar: true, src: '/images/games/briscamas/64x64.png' },
    },
    {
        key: 'briscolapiu',
        text: 'Briscolapiu',
        value: 'briscolapiu',
        image: { avatar: true, src: '/images/games/briscolapiu/64x64.png' },
    },
    {
        key: 'burracopiu',
        text: 'Burracopiu',
        value: 'burracopiu',
        image: { avatar: true, src: '/images/games/burracopiu/64x64.png' },
    },
    {
        key: 'buracoplus',
        text: 'Buracoplus',
        value: 'buracoplus',
        image: { avatar: true, src: '/images/games/burracopiu/64x64.png' },
    },
    {
        key: 'damapiu',
        text: 'Damapiu',
        value: 'damapiu',
        image: { avatar: true, src: '/images/games/damapiu/64x64.png' },
    },
    {
        key: 'escobamas',
        text: 'Escobamas',
        value: 'escobamas',
        image: { avatar: true, src: '/images/games/escobamas/64x64.png' },
    },
    {
        key: 'raminopiu',
        text: 'Raminopiu',
        value: 'raminopiu',
        image: { avatar: true, src: '/images/games/raminopiu/64x64.png' },
    },
    {
        key: 'rubamazzopiu',
        text: 'Rubamazzopiu',
        value: 'rubamazzopiu',
        image: { avatar: true, src: '/images/games/rubamazzopiu/64x64.png' },
    },
    {
        key: 'scacchipiu',
        text: 'Scacchipiu',
        value: 'scacchipiu',
        image: { avatar: true, src: '/images/games/scacchipiu/64x64.png' },
    },
        {
        key: 'scala40piu',
        text: 'Scala40piu',
        value: 'scala40piu',
        image: { avatar: true, src: '/images/games/scala40piu/64x64.png' },
    },
    {
        key: 'scopapiu',
        text: 'Scopapiu',
        value: 'scopapiu',
        image: { avatar: true, src: '/images/games/scopapiu/64x64.png' },
    },
    {
        key: 'scoponepiu',
        text: 'Scoponepiu',
        value: 'scoponepiu',
        image: { avatar: true, src: '/images/games/scoponepiu/64x64.png' },
    },
    {
        key: 'solitariopiu-freecell',
        text: 'Solitario Freecell',
        value: 'solitariopiu-freecell',
        image: { avatar: true, src: '/images/games/solitariopiu-freecell/64x64.png' },
    },
    {
        key: 'solitariopiu-tripeaks',
        text: 'Solitario Tripeaks',
        value: 'solitariopiu-tripeaks',
        image: { avatar: true, src: '/images/games/solitariopiu-freecell/64x64.png' },
    },
    {
        key: 'solitariopiu-klondike',
        text: 'Solitario Klondike',
        value: 'solitariopiu-klondike',
        image: { avatar: true, src: '/images/games/solitariopiu-klondike/64x64.png' },
    },
    {
        key: 'solitariopiu-pyramid',
        text: 'Solitario Pyramid',
        value: 'solitariopiu-pyramid',
        image: { avatar: true, src: '/images/games/solitariopiu-klondike/64x64.png' },
    },
    {
        key: 'solitariopiu-spider',
        text: 'Solitario Spider',
        value: 'solitariopiu-spider',
        image: { avatar: true, src: '/images/games/solitariopiu-klondike/64x64.png' },
    },
    {
        key: 'traversonepiu',
        text: 'Traversonepiu',
        value: 'traversonepiu',
        image: { avatar: true, src: '/images/games/traversonepiu/64x64.png' },
    },
    {
        key: 'tressettepiu',
        text: 'Tressettepiu',
        value: 'tressettepiu',
        image: { avatar: true, src: '/images/games/tressettepiu/64x64.png' },
    },
    {
        key: 'ALL',
        text: 'All',
        value: 'ALL',
        image: { avatar: true, src: '/images/games/ALL/64x64.png' },
    },
]

module.exports = { games, apiServer }
