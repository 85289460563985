// import React, { useContext } from 'react'
import React from 'react'

import { List, Image, Header } from 'semantic-ui-react'

import { Link } from 'react-router-dom'

// import { Context } from './Context'

// import ErrorMessage from './ErrorMessage'

// renderizza anche solo guid (ad esempio quando vengono passate le relationships)
const Item = ({ shortId, name, members, completeUrl }) => {
	return (
		<List.Item>
			<Image size="tiny" src={completeUrl} />
			<List.Content>
				<List.Header>{name}</List.Header>
				<Link to={`/group/shortId/${shortId}`}>{shortId}</Link>
			</List.Content>
		</List.Item>
	)
}

// funziona sia con props che context, props ha la precedenza
const GroupList = ({ list, count }) => {
	// const { players } = useContext(Context)
	// let final = []
	// if (list.length) {
	// 	final = list
	// } else if (players.length) {
	// 	final = players
	// }

	if (!list.length) return 'Empty List '
	return (
		<div>
			<Header>Group List</Header>
			<List celled>
				{list.map((group, i) => (
					<Item key={i} {...group} />
				))}
			</List>
		</div>
	)
}

export default GroupList
