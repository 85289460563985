import { apiServer } from '../settings'
import {getToken} from './token'

// From server  {success, data: [{game, player}] }

const getUserByEmail = (game, email, done) => {
	if (!game) return done('missing game')
	if (!email) return done('missing email')

	const headers = new Headers({'Authorization': 'Bearer ' + getToken()})

	const options = { method: 'GET', headers, mode: 'cors', cache: 'default' }

	const url = `${apiServer}/user-by-email?game=${game}&email=${email}`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.data)
			if (!response.success) return done('response without success true|false')
		})
}

export default getUserByEmail
