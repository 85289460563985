import { apiServer } from '../settings'
import {getToken} from './token'

const getGroupByShortId = (game, shortid, done) => {
	if (!game) return done('missing game')
	if (!shortid) return done('missing shortid')

	const headers = new Headers({'Authorization': 'Bearer ' + getToken()})

	const options = { method: 'GET', headers, mode: 'cors', cache: 'default' }

	const url = `${apiServer}/group-by-shortid?game=${game}&shortid=${shortid}`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.group)
			if (!response.success) return done('response without success true|false')
		})
}


export default getGroupByShortId
