import React from 'react'

const BannedUsersContext = React.createContext()

const useBannedUsersContext = () => {
	const context = React.useContext(BannedUsersContext)
	if (!context) {
		throw new Error(`useCount must be used within a CountProvider`)
	}
	return context
}

const BannedUsersProvider = props => {
	const [resultFromApi, setResultFromApi] = React.useState(null)
	const value = React.useMemo(() => [resultFromApi, setResultFromApi], [resultFromApi])
	return <BannedUsersContext.Provider value={value} {...props} />
}

export  { BannedUsersProvider, useBannedUsersContext }