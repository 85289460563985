import { apiServer } from '../settings'
import { getToken } from './token'
import createLog from '../utils/create-log'

const clearCache = (userGuid, userAuthorization, game, guid, done) => {
	if (!userGuid) return done('missing userGuid')
	if (!userAuthorization) return done('missing userAuthorization')
	if (!game) return done('missing game')
	if (!guid) return done('missing guid')

	const headers = new Headers({
		Authorization: 'Bearer ' + getToken(),
		'Content-Type': 'application/json',
	})

	const options = {
		method: 'PUT',
		headers,
		mode: 'cors',
		cache: 'default',
		body: JSON.stringify({
			log: createLog(userGuid, userAuthorization, game, `clear cache ${guid}`),
		}),
	}

	const url = `${apiServer}/user-clear-cache?game=${game}&guid=${guid}`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			if (response.success === true) return done()
			if (!response.success) return done('response without success true|false')
		})
}

export default clearCache
