import React, { useContext, useState, useEffect } from 'react'

import { Context } from '../Context'
import Loader from '../Loader'
import { getJackpotsByType } from '../api'
import JackpotsTable from './JackpotsTable'

// const fakeJackpotsToRemove = [
//     {
//         level: 'beginner',
//         period: '2023:12',
//         value: 666,
//     },
//     {
//         level: 'beginner',
//         period: '2024:01',
//         value: 555,
//     },
//     {
//         level: 'intermediate',
//         period: '2023:12',
//         value: 123,
//     },
//     {
//         level: 'intermediate',
//         period: '2024:01',
//         value: 125,
//     },
// ]

function Jackpots() {
    const { game } = useContext(Context)
    const [jackpots, setJackpots] = useState()
    const [type, setType] = useState('monthly')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getJackpotsByType(game, type, (err, _jackpots) => {
            if (err) {
                console.log(err)
                setLoading(false)
                return
            }
            setLoading(false)
            // setJackpots(fakeJackpotsToRemove)
            setJackpots(_jackpots)
        })
    }, [game, type])

    if (loading) return <Loader style={{ height: '150%' }} />
    return <JackpotsTable jackpots={jackpots} type={type} />
}
export default Jackpots
