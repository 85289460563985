import React, { useContext } from 'react'

import { List, Image, Header } from 'semantic-ui-react'

import { Link } from 'react-router-dom'

import { Context } from './Context'

import ErrorMessage from './ErrorMessage'

// renderizza anche solo guid (ad esempio quando vengono passate le relationships)
const Item = ({ guid, pixUrl, avatar, nickname, game, err }) => {
    const finalPixUrl = pixUrl || '/images/avatars/0.jpg'
    const finalNickname = nickname || '...'
    if (err) return <ErrorMessage title={guid} body={err} />
    return (
        <List.Item>
            <Image size="tiny" src={finalPixUrl} />
            <List.Content>
                <List.Header>{finalNickname} </List.Header>
                <List.Description>{game}</List.Description>
                <Link to={`/user/guid/${guid}`}>{guid}</Link>
            </List.Content>
        </List.Item>
    )
}

// funziona sia con props che context, props ha la precedenza
const UserList = ({ list, count }) => {
    const { players } = useContext(Context)
    let final = []
    if (list.length) {
        final = list
    } else if (players.length) {
        final = players
    }

    if (!final.length) return 'Empty List '
    return (
        <div>
            <Header>Users Found {count}</Header>
            <List celled>
                {final.map((element, i) => (
                    <Item key={i} {...element} />
                ))}
            </List>
        </div>
    )
}

export default UserList
