import React, {useState } from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'

const ModalOk = props => {
    const [isOpen, setOpen] = useState(true)
    return (
        <Modal open={isOpen} size="small">
            <Header icon="info" content={props.title} />
            <Modal.Content>
                <p>{props.text}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color="green"
                    onClick={() => {
                        setOpen(false)
                        props.callbackOk()
                    }}>
                    Ok
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ModalOk
