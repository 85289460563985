import React, { useState, useEffect } from 'react'
import { Table, Modal, Button, Header } from 'semantic-ui-react'
import moment from 'moment'
import { getMessagesByConvId } from '../../api'
import { Context } from '../../Context'

// const MessagesModal = ({ messages }) => {
//     const [isOpen, setOpen] = useState(false)
//     // trigger={<Button>View</Button>}>
//     useEffect(() => {
//         // Apre il modal solo se messages.length è diverso da zero
//         setOpen(messages.length !== 0);
//     }, [messages]);

//     // console.log('messages', messages)
//     return (
//         <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={isOpen}>
//             <Modal.Header>Messages</Modal.Header>
//             <Modal.Content>
//                 <Modal.Description>
//                     {messages.map((msg, index) => (
//                         <div key={index}>
//                             {moment(msg.timestamp).format('lll')} - ({msg.fromGuid}) - {msg.text}
//                         </div>
//                     ))}
//                 </Modal.Description>
//             </Modal.Content>
//             <Modal.Actions>
//                 <Button
//                     content="OK"
//                     labelPosition="right"
//                     icon="checkmark"
//                     onClick={() => setOpen(false)}
//                     positive
//                 />
//             </Modal.Actions>
//         </Modal>
//     )
// }

// const formatPlayersInfo = playersInfo => {
//     return `${playersInfo[0].guid}:${playersInfo[0].notReadCount}<br/>${playersInfo[1].guid}:${playersInfo[1].notReadCount}`
// }

// {
//     "_id": "653a85736d1267c1c1846089",
//     "deviceId": "21c5c59e-d790-4787-87c5-8c886909681d",
//     "UTCoffset": 1,
//     "__v": 0,
//     "clientVersion": "9.11.0",
//     "createdAt": "2023-10-26T15:27:47.314Z",
//     "expiresAt": "2024-06-19T16:05:55.897Z",
//     "guid": "2e4f2e76-4da0-4223-9821-99013ce82a88",
//     "language": "it",
//     "lastAccessedAt": "2023-12-22T17:05:55.897Z",
//     "lastAdConsentGivenAt": "2023-04-06T11:57:48.000Z",
//     "lastAdConsentRefusedAt": "2022-02-09T08:53:03.000Z",
//     "platform": "web",
//     "policyAcceptedAt": "2023-10-26T15:27:41.000Z",
//     "termsAcceptedAt": "2023-10-26T15:27:41.000Z",
//     "timezone": "Europe/Rome"
// }

const formatDate = date => {
    if (!date) return ''
    return moment(date).format('MMM D, YYYY')
    
}

const InstallationsTable = ({ installations }) => {
    const { game, player } = React.useContext(Context)
    // const [messages, setMessages] = useState([])
    const [error, setError] = useState('')

    // const handleCellClick = convId => {
    //     setError("")
    //     getMessagesByConvId(game, convId, (error, messages) => {
    //         if (error) {
    //             console.error('Errore durante il recupero dei messaggi:', error)
    //             return
    //         }
    //         if (!messages) {
    //             setError("Nessun messaggio associato al convId")
    //             return;
    //         }
    //         // console.log(messages)
    //         messages.sort((a, b) => new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf())
    //         setMessages([...messages])
    //     })
    // }

    if (installations.length === 0) return <div>No installations found.</div>
    return (
        <>
            {error && (
                <Header as="h4" color="red">
                    {error}
                </Header>
            )}
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>DeviceId</Table.HeaderCell>
                        {/* <Table.HeaderCell>UTCoffset</Table.HeaderCell> */}
                        <Table.HeaderCell>timezone</Table.HeaderCell>
                        <Table.HeaderCell>client</Table.HeaderCell>
                        <Table.HeaderCell>lang</Table.HeaderCell>
                        <Table.HeaderCell>platform</Table.HeaderCell>

                        <Table.HeaderCell>created</Table.HeaderCell>
                        <Table.HeaderCell>expires</Table.HeaderCell>
                        <Table.HeaderCell>last Access</Table.HeaderCell>
                        <Table.HeaderCell>last AdConsent Given</Table.HeaderCell>
                        <Table.HeaderCell>last AdConsent Refused</Table.HeaderCell>
                        <Table.HeaderCell>policy Accepted</Table.HeaderCell>
                        <Table.HeaderCell>terms Accepted</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {installations.map((inst, index) => {
                        // console.log('types.actionType', event, types.actionType)
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>{inst.deviceId}</Table.Cell>
                                {/* <Table.Cell>{inst.UTCoffset}</Table.Cell> */}
                                <Table.Cell>{inst.timezone}<br/>({inst.UTCoffset})</Table.Cell>
                                <Table.Cell>{inst.clientVersion}</Table.Cell>
                                <Table.Cell>{inst.language}</Table.Cell>
                                <Table.Cell>{inst.platform}</Table.Cell>

                                <Table.Cell>{formatDate(inst.createdAt)}</Table.Cell>
                                <Table.Cell>{formatDate(inst.expiresAt)}</Table.Cell>
                                <Table.Cell>{formatDate(inst.lastAccessedAt)}</Table.Cell>
                                <Table.Cell>{formatDate(inst.lastAdConsentGivenAt)}</Table.Cell>
                                <Table.Cell>{formatDate(inst.lastAdConsentRefusedAt)}</Table.Cell>
                                <Table.Cell>{formatDate(inst.policyAcceptedAt)}</Table.Cell>
                                <Table.Cell>{formatDate(inst.termsAcceptedAt)}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
                {/* <MessagesModal messages={messages} /> */}
            </Table>
        </>
    )
}

export default InstallationsTable
