import React, { useContext } from 'react'

import { Header, List } from 'semantic-ui-react'

import { Context } from '../../Context'

import moment from 'moment'

const Item = ({ type, createdAt, data }) => {
	const created = moment(createdAt).format('lll')
	return (
		<List.Item>
			<List.Content>
				<List.Description><b>Type:</b> {type}</List.Description>
				<List.Description><b>Created:</b> {created}</List.Description>
				<List.Description><b>Data:</b> {data}</List.Description>
			</List.Content>
		</List.Item>
	)
}

const NotificationList = () => {
	const { player } = useContext(Context)
	if (!player) return 'No Player Set'
	if (!player.notifications || player.notifications.length === 0) return 'No Notifications'
	return (
		<div>
			<Header>Notifications</Header>
			<List celled>
				{player.notifications.map((notification, index) => (
					<Item key={index} {...notification} />
				))}
			</List>
		</div>
	)
}

export default NotificationList
