import { apiServer } from '../settings'
import {getToken} from './token'

const getUserGroupsInfo = (game, groups, done) => {
	if (!game) return done('missing game')
	if (!groups) return done('missing groups')

	const headers = new Headers({'Authorization': 'Bearer ' + getToken()})

	const options = { method: 'GET', headers, mode: 'cors', cache: 'default' }

	const groupsStr = groups.join(',')

	const url = `${apiServer}/groups-info?game=${game}&groups=${groupsStr}`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.data)
			if (!response.success) return done('response without success true|false')
		})
}


export default getUserGroupsInfo
