import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { useParams, useLocation } from 'react-router-dom'

import { Pagination } from 'semantic-ui-react'

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

// MyPaginator
// si sincronizza con l'url
// si aggiorna con l'interazione della sua ui
// effettua una chiamata al server
const MyPaginator = props => {
	const { history, entity } = props
	const { nick } = useParams()
	const query = useQuery()
	const insensitive = query.get('insensitive')
	const contains = query.get('contains')
	let skip = query.get('skip')
	const limit = query.get('limit')
	const ENTITY_PER_PAGE = 10
	const [currentPage, setCurrentPage] = useState(parseInt(skip / ENTITY_PER_PAGE) + 1)

	const handlePaginationChange = (e, { activePage }) => {
		setCurrentPage(activePage)

		skip = (activePage - 1) * ENTITY_PER_PAGE
		history.push(
			`/${entity}/nick/${nick}?insensitive=${insensitive}&contains=${contains}&skip=${skip}&limit=${limit}`
		)
	}

	return (
		<Pagination
			onPageChange={handlePaginationChange}
			defaultActivePage={currentPage}
			boundaryRange={1}
			siblingRange={0}
			firstItem={null}
			lastItem={null}
			totalPages={props.totalPages}
		/>
	)
}

export default withRouter(MyPaginator)
