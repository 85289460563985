import { apiServer } from '../settings'
import {getToken} from './token'

const getInstallations= (game, guid, done) => {
	if (!game) return done('missing game')
	if (!guid) return done('missing guid')

	const headers = new Headers({'Authorization': 'Bearer ' + getToken()})

	const options = { method: 'GET', headers, mode: 'cors', cache: 'default' }

	const url = `${apiServer}/installations-by-guid?game=${game}&guid=${guid}`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			// console.log(response)
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.result)
			if (!response.success) return done('response without success true|false')
		})
}


export default getInstallations
