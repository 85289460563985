import React, { useState } from 'react'
import { Button, Form, Segment, Container } from 'semantic-ui-react'
import { Context } from './Context'
import { useAuthorization } from './Contexts/AuthorizationContext'
import Loader from './Loader'
import ErrorMessage from './ErrorMessage'

const Login = () => {
	const { game } = React.useContext(Context)
	const { login, loading, error } = useAuthorization()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleChangeEmail = (e, { name, value }) => setEmail(value)
	const handleChangePassword = (e, { name, value }) => setPassword(value)

	const handleSubmit = () => {
		login(game, email, password)
	}

	const style = { marginLeft: 'auto', marginRight: 'auto', marginTop: '150px', width: '50%' }


	if (loading) return <Loader />
	return (
		<Container>
			<Segment style={style}>
				<Form onSubmit={handleSubmit}>
					<Form.Input
						placeholder='Email'
						name="email"
						value={email}
						onChange={handleChangeEmail}
					/>
					<Form.Input
						placeholder='Password'
						type="password"
						name="password"
						value={password}
						onChange={handleChangePassword}
					/>
					<Button type="submit">Login</Button>
					{error && <ErrorMessage title="Error" body={error} />}
				</Form>
			</Segment>
		</Container>
	)
}

export default Login
