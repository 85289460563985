import React from 'react'
import { withRouter } from 'react-router'

import { Form } from 'semantic-ui-react'

import { Context } from './Context'

const PIX = '2px'
class UserSearch extends React.Component {
	static contextType = Context

	constructor(props) {
		super(props)
		this.state = {
			searchText: '',
			contains: false,
			insensitive: false,
		}
	}

	handleChange = (e, { name, value }) => this.setState({ searchText: value })
	toggleContains = () => this.setState(prevState => ({ contains: !prevState.contains }))
	toggleInsensitive = () => this.setState(prevState => ({ insensitive: !prevState.insensitive }))
	render() {
		const { history } = this.props
		const { game } = this.context
		const noGameSelected = !game
		const isAll = game === 'ALL'
		const { searchText } = this.state
		return (
			<Form>
				<Form.Group style={{ justifyContent: 'center'}}>
					<Form.Input
						width={16}
						disabled={noGameSelected}
						style={{ marginTop: PIX, marginBottom: PIX }}
						icon={{ name: 'search', circular: true, link: true }}
						placeholder={'Search user...'}
						name="searchText"
						value={searchText}
						onChange={this.handleChange}
					/>
				</Form.Group>
				<Form.Group inline style={{ justifyContent: 'center'}}>
					<Form.Button
						disabled={noGameSelected || !searchText || isAll}
						style={{ marginTop: PIX, marginBottom: PIX, width:"110px" }}
						onClick={() => {
							history.push('/user/guid/' + searchText)
						}}>
						By Guid
					</Form.Button>
					<Form.Button
						disabled={noGameSelected || !searchText || isAll}
						style={{ marginTop: PIX, marginBottom: PIX, width:"110px" }}
						onClick={() => {
							history.push('/user/shortid/' + searchText)
						}}>
						By ShortId
					</Form.Button>
					<Form.Button
						disabled={noGameSelected || !searchText}
						style={{ marginTop: PIX, marginBottom: PIX, width:"110px" }}
						onClick={() => {
							history.push('/user/email/' + searchText)
						}}>
						By Email
					</Form.Button>
					<Form.Button
						disabled={noGameSelected || !searchText || isAll}
						style={{ marginTop: PIX, marginBottom: PIX, width:"110px" }}
						onClick={() => {
							const { contains, insensitive } = this.state
							const skip = 0
							const limit = 20
							history.push(
								`/user/nick/${searchText}?insensitive=${insensitive}&contains=${contains}&skip=${skip}&limit=${limit}`
							)
						}}>
						By Nick
					</Form.Button>

					<Form.Checkbox
						style={{ margin: PIX }}
						disabled={noGameSelected || !searchText || isAll}
						label="contains"
						onChange={this.toggleContains}
					/>
					<Form.Checkbox
						style={{ margin: PIX }}
						disabled={noGameSelected || !searchText || isAll}
						label="insensitive"
						onChange={this.toggleInsensitive}
					/>
				</Form.Group>
			</Form>
		)
	}
}

export default withRouter(UserSearch)
