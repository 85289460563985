import React, { useContext, useState, useEffect } from 'react'

import { Context } from '../Context'
import Loader from '../Loader'
import { getAllBots } from '../api'
import UserList from '../UserList'

function Bots() {
    const { game } = useContext(Context)
    const [bots, setBots] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getAllBots(game, (err, _bots) => {
            if (err) {
                console.log(err)
                setLoading(false)
                return
            }
            setLoading(false)
            setBots(_bots)
        })
    }, [game])

    if (loading) return <Loader style={{ height: '150%' }} />
    return <UserList list={bots} count={bots.lenght} />
}
export default Bots
