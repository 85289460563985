import React from 'react'

import { Header, Table } from 'semantic-ui-react'
import { Progress } from 'semantic-ui-react'


const Level = ({ levelInfo }) => {
    return (
        <Progress
            color="yellow"
            label={'Level ' + levelInfo.level}
            percent={(levelInfo.levelScore / levelInfo.nextLevelScore) * 100}
        />
    )
}

const ScoreLevelCounters = ({ guid, elo, levelInfo, scores, counters, trophies }) => {
    if (!guid || !levelInfo) return 'Missing data'
    // console.log('levelInfo',levelInfo)
    // console.log('scores',scores)
    // console.log('counters',counters)
    // console.log('trophies',trophies)
    return (
        <div style={{ paddingBottom: '25px' }}>
            <Header as="h2">Guid {guid}</Header>
            <Level levelInfo={levelInfo} />
            <Header as="h2">Elo {elo}</Header>
            <Header as="h1">Scores</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {scores.alltime.length === 0 && scores.monthly.length === 0 && <div>no scores</div>}
                    {scores.alltime.map((scoreInfo, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{scoreInfo.level}</Table.Cell>
                            <Table.Cell>{scoreInfo.period}</Table.Cell>
                            <Table.Cell>{scoreInfo.value}</Table.Cell>
                        </Table.Row>
                    ))}
                    {scores.monthly.map((scoreInfo, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{scoreInfo.level}</Table.Cell>
                            <Table.Cell>{scoreInfo.period}</Table.Cell>
                            <Table.Cell>{scoreInfo.value}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Header as="h1">Counters</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Draw</Table.HeaderCell>
                        <Table.HeaderCell>Loss</Table.HeaderCell>
                        <Table.HeaderCell>Unfinished Connected</Table.HeaderCell>
                        <Table.HeaderCell>Unfinished Disconnected</Table.HeaderCell>
                        <Table.HeaderCell>Unfinished Abandoned</Table.HeaderCell>
                        <Table.HeaderCell>Win</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {counters.alltime.length === 0 && counters.monthly.length === 0 && <div>no counters</div>}
                    {counters.alltime.map((counterInfo, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{counterInfo.level}</Table.Cell>
                            <Table.Cell>{counterInfo.period}</Table.Cell>
                            <Table.Cell>{counterInfo.value.draw}</Table.Cell>
                            <Table.Cell>{counterInfo.value.loss}</Table.Cell>
                            <Table.Cell>{counterInfo.value.unfinished.connected}</Table.Cell>
                            <Table.Cell>{counterInfo.value.unfinished.disconnected}</Table.Cell>
                            <Table.Cell>{counterInfo.value.unfinished.abandoned}</Table.Cell>
                            <Table.Cell>{counterInfo.value.win}</Table.Cell>
                        </Table.Row>
                    ))}
                    {counters.monthly.map((counterInfo, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{counterInfo.level}</Table.Cell>
                            <Table.Cell>{counterInfo.period}</Table.Cell>
                            <Table.Cell>{counterInfo.value.draw}</Table.Cell>
                            <Table.Cell>{counterInfo.value.loss}</Table.Cell>
                            <Table.Cell>{counterInfo.value.unfinished.connected}</Table.Cell>
                            <Table.Cell>{counterInfo.value.unfinished.disconnected}</Table.Cell>
                            <Table.Cell>{counterInfo.value.unfinished.abandoned}</Table.Cell>
                            <Table.Cell>{counterInfo.value.win}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Header as="h1">Trophies</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Rank</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.keys(trophies).length === 0 && <div>no trophies</div>}
                    {Object.keys(trophies).length > 0 && trophies?.monthly?.map((trophyInfo, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>Monthly</Table.Cell>
                            <Table.Cell>{trophyInfo.level}</Table.Cell>
                            <Table.Cell>{trophyInfo.period}</Table.Cell>
                            <Table.Cell>{trophyInfo.rank}</Table.Cell>
                        </Table.Row>
                    ))}
                    {Object.keys(trophies).length > 0 && trophies?.dailychallenge?.map((trophyInfo, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>Daily Challenge</Table.Cell>
                            <Table.Cell>{trophyInfo.level}</Table.Cell>
                            <Table.Cell>{trophyInfo.period}</Table.Cell>
                            <Table.Cell>{trophyInfo.rank}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

export default ScoreLevelCounters
