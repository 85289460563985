import React, { useState, useEffect } from 'react'
import { Context } from '../../Context'
import { Header, Container, Loader } from 'semantic-ui-react'
import { getInstallations } from '../../api'
import InstallationsTable from './InstallationsTable'
// import SweetPagination from 'sweetpagination'

function UserInstallations() {
    const { game, player } = React.useContext(Context)
    const [isLoading, setIsLoading] = useState(false)
    const [installations, setInstallations] = useState([])

    // PAGINATION
    // const [currentPageData, setCurrentPageData] = useState(new Array(2).fill());
    // const [_, setCurrentPageData] = useState([]);

    // const fakeCollectiblesToRemove = [
    //     {
    //       id: 'item-01',
    //       category: 'category-01',
    //       subcategory: 'subcategory-01',
    //       value: 66.66,
    //       createdAt: '2023-12-14T11:42:20.051Z'
    //     },
    //     {
    //       id: 'item-02',
    //       category: 'category-02',
    //       subcategory: 'subcategory-02',
    //       value: 66.66,
    //       createdAt: '2023-12-14T11:42:20.061Z'
    //     }
    //   ]
    useEffect(() => {
        setIsLoading(true)
        getInstallations(game, player.guid, (err, data) => {
            console.log('****DATA', err, data)
            setIsLoading(false)
            // data.sort((a, b) => new Date(b.lastUpdatedAt).valueOf() - new Date(a.lastUpdatedAt).valueOf())
            setInstallations(data)
        })
    }, [])

    if (!player) return null

    // console.log('events', events)
    // console.log('currentPageData', currentPageData)
    return (
        <Container>
            <Header>User Installations</Header>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <InstallationsTable installations={installations} />
                    {/* <SweetPagination
                        currentPageData={setCurrentPageData}
                        getData={conversations}
                        dataPerPage={30}
                        navigation={true}
                    /> */}
                </div>
            )}
        </Container>
    )
}
export default UserInstallations
