import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Header, Icon } from 'semantic-ui-react'
import moment from 'moment'

const Item = ({ bannedAt, guid }) => {
	return (
		<div>
			<Icon name="user outline" />
			<Link to={`/user/guid/${guid}`}>{guid}</Link>
			{ bannedAt && <span> - {moment(bannedAt).format('lll')}</span>}
		</div>
	)
}

// result è un array di oggetti
/*
	{guid, users: [{guid, createdAt}], count}
*/
const BanList2 = ({ title, result, history }) => {
	return (
		<div>
			<Header>Banned Users - {title}</Header>
			{result.length === 0
				? 'Empty List'
				: result.map((el, key) => <Item key={key} guid={el.guid} bannedAt={el.bannedAt} />)}
		</div>
	)
}

export default withRouter(BanList2)
