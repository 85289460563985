import { apiServer } from '../settings'
import {getToken} from './token'

const getAllBanStats = (game, limit, hours, done) => {
	if (!game) return done('missing game')
	if (!limit) return done('missing limit')
	if (!hours) return done('missing hours')

	const headers = new Headers({'Authorization': 'Bearer ' + getToken()})

	const options = { method: 'GET', headers, mode: 'cors', cache: 'default' }

	const url = `${apiServer}/get-all-ban-stats?game=${game}&hours=${hours}&limit=${limit}`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.result)
			if (!response.success) return done('response without success true|false')
		})
		.catch(error=> {
			return done(error.toString())
		})

}


export default getAllBanStats
