import React, { useState, useContext } from 'react'
import { Divider } from 'semantic-ui-react'
import { withRouter } from 'react-router'

import GroupInfo from './GroupInfo'
import GroupCommands from './GroupCommands'
import Loader from '../Loader'

import { Context } from '../Context'
// import { useAuthorization } from '../Contexts/AuthorizationContext'

import {
	deleteGroup,
	banGroup,
} from '../api'
import ModalConfirm from '../modals/ModalConfirm'
import ModalOk from '../modals/ModalOk'

const GroupInfoAndCommands = ({ history }) => {
	const { group, setGroup, game } = useContext(Context)
	let {isBanned} = group
	// let { isExclusive, isInviteOnly } = group

	// const { user } = useAuthorization()

	const [loading, setLoading] = useState(false)

	const [messageDelete, setMessageDelete] = useState(null)
	const [messageBan, setMessageBan] = useState(null)
	const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false)
	const [shouldConfirmBan, setShouldConfirmBan] = useState(false)

	const onDeleteHandler = () => {
		setShouldConfirmDelete(true)
	}
	const onBanHandler = () => {
		setShouldConfirmBan(true)
	}

	if (!game) return null
	if (!group) return null

	if (loading) return <Loader />

	if (shouldConfirmDelete)
		return (
			<ModalConfirm
				text="Sure?"
				callbackYes={() => {
					setLoading(true)
					setShouldConfirmDelete(false)
					deleteGroup(game, group.shortId, err => {
						setLoading(false)
						if (err) return setMessageDelete(err)
						setMessageDelete('OK!')
					})
				}}
				callbackNo={() => {
					setShouldConfirmDelete(false)
				}}
			/>
		)
	
	if (shouldConfirmBan)
		return (
			<ModalConfirm
				text="Sure?"
				callbackYes={() => {
					setLoading(true)
					setShouldConfirmBan(false)
					isBanned = !isBanned
					banGroup(game, group.shortId, isBanned, err => {
						setLoading(false)
						if (err) return setMessageBan(err)
						setMessageBan('OK!')
					})
				}}
				callbackNo={() => {
					setShouldConfirmBan(false)
				}}
			/>
		)


	if (messageDelete)
		return (
			<ModalOk
				title="Result"
				text={messageDelete}
				callbackOk={() => {
					setGroup(null)
					setMessageDelete(null)
					history.push('/')
				}}
			/>
		)

if (messageBan)
		return (
			<ModalOk
				title="Result"
				text={messageBan}
				callbackOk={() => {
					setMessageBan(null)
					group.isBanned = !isBanned
					setGroup(group)
					// console.log('setting group', group.isBanned)
				}}
			/>
		)

	return (
		<div>
			{/*<Divider />*/}
			<GroupInfo />
			<Divider />
			<GroupCommands
				onDeleteHandler={onDeleteHandler}
				onBanHandler={onBanHandler}
				isBanned={isBanned}
			/>
		</div>
	)
}

export default withRouter(GroupInfoAndCommands)
