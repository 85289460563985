import React, { useContext } from 'react'

import { Header, List, Image } from 'semantic-ui-react'


// import { Link } from 'react-router-dom'

import { Context } from './Context'

// renderizza anche solo guid (ad esempio quando vengono passate le relationships)
const Item = ({ picture, type, externalId, email, firstName, lastName, createdAt }) => {
	return (
		<List.Item>
			<Image size="tiny" src={picture} />
			<List.Content>
				<List.Description><b>Type:</b> {type}</List.Description>
				<List.Description><b>Exernal Id:</b> {externalId}</List.Description>
				<List.Description>
				{/*<Link to={`/user/email/${email}`}>{email}</Link>}*/}
				<b>Email:</b> {email}
				</List.Description>
				<List.Description><b>Name:</b> {firstName}</List.Description>
				<List.Description><b>Lastname:</b> {lastName}</List.Description>
				<List.Description><b>Created:</b> {createdAt}</List.Description>
			</List.Content>
		</List.Item>
	)
}

const AuthMechList = () => {
	const { player } = useContext(Context)
	if (!player) return null
	if (!player.authMechs || player.authMechs.length === 0) return 'No Auth Mechs'
	return (
		<div>
			<Header>Auth Mechs</Header>
			<List celled>
				{player.authMechs.map((authMech, index) => (
					<Item key={index} {...authMech} />
				))}
			</List>
		</div>

	)
}

export default AuthMechList
