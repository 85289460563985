import React, { useState, useContext } from 'react'

import { withRouter } from 'react-router'
// import { Link } from 'react-router-dom'
import { Form, Button } from 'semantic-ui-react'
import { getBannedUsers } from '../api'

import Loader from '../Loader'
import ErrorMessage from '../ErrorMessage'

import { Context } from '../Context'
import { useBannedUsersContext } from '../Contexts/BannedUsersContext'
// import {useNonInitialEffect} from '../utils/use-non-initial-effect'
import BanList2 from './BanList2'

const sortByDate = arr => {
    arr.sort(function(a, b) {
        
        if (!a.bannedAt || !b.bannedAt) return 0

        var keyA = new Date(a.bannedAt),
            keyB = new Date(b.bannedAt)
        // Compare the 2 dates
        if (keyA < keyB) return 1
        if (keyA > keyB) return -1
        return 0
    })
}

const PIX = '5px'

const resultsOption = [
    {
        key: 10,
        text: 10,
        value: 10,
    },
]

// const fakeResultCancellare = {
//     chat: [
//         { guid: '9b3ad634-d71e-42d1-bf9a-9fedaedd9b73', isBanned: true },
//         { guid: '9b3ad634-d71e-42d1-bf9a-9fedaedd9b73', isBanned: true },
//         { guid: '9b3ad634-d71e-42d1-bf9a-9fedaedd9b73', isBanned: true },
//     ],
//     picture: [
//         { guid: '9b3ad634-d71e-42d1-bf9a-9fedaedd9b73', isBanned: true },
//         { guid: '9b3ad634-d71e-42d1-bf9a-9fedaedd9b73', isBanned: true },
//         { guid: '9b3ad634-d71e-42d1-bf9a-9fedaedd9b73', isBanned: true },
//         { guid: '9b3ad634-d71e-42d1-bf9a-9fedaedd9b73', isBanned: true },
//         { guid: '9b3ad634-d71e-42d1-bf9a-9fedaedd9b73', isBanned: true },
//     ],
//     nickname: [],
// }

const BannedUsers = ({ history }) => {
    const { game } = useContext(Context)
    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(false)
    const [numberOfResults, setNumberOfResults] = useState(resultsOption[0].value)
    // const [days, setDays] = useState(dayOption[0].value)
    // GESTIONE LOCALE
    // const [resultFromApi, setResultFromApi] = useState(null)
    const [resultFromApi, setResultFromApi] = useBannedUsersContext()

    const handleNumberOfResults = (e, { value }) => setNumberOfResults(value)
    // const handleDays = (e, { value }) => setDays(value)
    const handleFetch = () => {
        setLoading(true)
        getBannedUsers(game, (err, result) => {
            setLoading(false)
            if (err) return setErrorMsg(err)
         
            sortByDate(result.chat)
            sortByDate(result.picture)
            sortByDate(result.nickname)
            // console.log(result)
            setResultFromApi(result)
        })
    }

    if (errorMsg) return <ErrorMessage title="Error" body={errorMsg} />
    if (loading) return <Loader />

    return (
        <div>
            <Form>
                <Form.Group inline style={{ justifyContent: 'center' }}>
                    <div style={{ marginRight: PIX }}>TODO</div>
                    <Form.Dropdown
                        button
                        value={numberOfResults}
                        options={resultsOption}
                        onChange={handleNumberOfResults}
                    />
                    <Button primary onClick={handleFetch}>
                        Fetch
                    </Button>
                </Form.Group>
            </Form>
            {resultFromApi && <BanList2 title="Chat" result={resultFromApi.chat} />}
            <br />
            {resultFromApi && <BanList2 title="Pix" result={resultFromApi.picture} />}
            <br />
            {resultFromApi && <BanList2 title="Nick" result={resultFromApi.nickname} />}
        </div>
    )
}

export default withRouter(BannedUsers)
