import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { getUserByShortId } from './api'

import UserInfoAndCommands from './user-info/UserInfoAndCommands'
import ErrorMessage from './ErrorMessage'

import { Context } from './Context'

const UserInfoLoaderByShortId = () => {
	const { shortid } = useParams()
	const { game, player, setPlayer } = useContext(Context)
	const [errorMsg, setErrorMsg] = useState(null)
	const isPlayer = !!player

	useEffect(() => {
		setPlayer(null)
		getUserByShortId(game, shortid, (err, player) => {
			if (err) return setErrorMsg(err)
			setPlayer(player)
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game, shortid])

	if (isPlayer) return <UserInfoAndCommands />
	if (errorMsg) return <ErrorMessage title="Error" body={errorMsg} />
	return null
}

export default UserInfoLoaderByShortId
