import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { getUserByGuid } from './api'

import UserInfoAndCommands from './user-info/UserInfoAndCommands'
import ErrorMessage from './ErrorMessage'

import { Context } from './Context'

const UserInfoLoaderByGuid = () => {
    const { guid } = useParams()
    const { game, player, setPlayer } = useContext(Context)
    const [errorMsg, setErrorMsg] = useState(null)
    const isPlayer = !!player

    useEffect(() => {
        setPlayer(null)
        getUserByGuid(game, guid, (err, player) => {
            if (err) return setErrorMsg(err)
            setPlayer(player)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game, guid])

    if (isPlayer) return <UserInfoAndCommands />
    if (errorMsg) return <ErrorMessage title="Error" body={errorMsg} />
    return null
}

export default UserInfoLoaderByGuid
