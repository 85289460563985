import React, { useState } from 'react'
import { Card, Image, Icon, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Context } from '../Context'
import ErrorMessage from '../ErrorMessage'
import Loader from '../Loader'
import { getUsersByGuid } from '../api'

import moment from 'moment'

const GroupInfo = ({ history }) => {
    const { group, setPlayers, game /*, setMainTabActiveIndex*/ } = React.useContext(Context)
    const { isExclusive, isInviteOnly, isBanned } = group

    const created = moment(group.createdAt).format('lll')
    const updatedAt = moment(group.updatedAt).format('lll')

    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleClickUserList = guids => {
        setLoading(true)
        getUsersByGuid(game, guids, (err, players) => {
            setLoading(false)
            if (err) return setErrorMsg(err)
            setPlayers(players)
            history.push('/users')
            // setMainTabActiveIndex(0)
        })
    }

    const Badge = ({ label, color }) => (
        <Label style={{ float: 'right' }} color={color ? color : 'pink'} horizontal>
            {label}
        </Label>
    )

    const membersArray = Object.values(group.members)

    const admins = membersArray.filter(m => m.role === 'admin').map(member => member.guid)
    const members = membersArray.filter(m => m.role === 'member').map(member => member.guid)
    const messageCount = group.messages.length

    // const admins = group.members.filter(m => m.role === 'admin').map(member => member.guid)
    // const members = group.members.filter(m => m.role === 'member').map(member => member.guid)
    // const messageCount = group.messages.length

    // console.log('GROUP', group)

    return (
        <div>
            {loading && <Loader style={{ height: '150%' }} />}
            <Card style={{ maxWidth: '383px' }} centered fluid>
                <Card.Content>
                    <Card.Header>
                        {isExclusive && <Badge label="Exclusive" color="green" />}
                        {isInviteOnly && <Badge label="Invite Only" color="green" />}
                        {isBanned && <Badge label="Banned" />}
                        {group.name}
                    </Card.Header>
                </Card.Content>
                <Image
                    label={{
                        color: 'blue',
                        content: 'Current',
                        icon: 'picture',
                        ribbon: true,
                    }}
                    style={{ padding: '50px' }}
                    src={group.completeUrl}
                    ui={false}
                    centered
                    wrapped
                    size="medium"
                />
                <Card.Content>
                    <Card.Header>{group.shortId}</Card.Header>
                    <Card.Meta>
                        <br />
                        <div>Created {created}</div>
                        <div>Updated At {updatedAt}</div>
                    </Card.Meta>
                    <Card.Description>{group.description}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    {/*eslint-disable-next-line*/}
                    <a
                        onClick={() => {
                            handleClickUserList(admins)
                        }}>
                        <Icon name="user" />
                        {admins ? admins.length : 0} Admins
                    </a>
                    {errorMsg && <ErrorMessage title="Error" body={errorMsg} />}
                </Card.Content>
                <Card.Content extra>
                    {/*eslint-disable-next-line*/}
                    <a
                        onClick={() => {
                            handleClickUserList(members)
                        }}>
                        <Icon name="user" />
                        {members ? members.length : 0} Members
                    </a>
                    {errorMsg && <ErrorMessage title="Error" body={errorMsg} />}
                </Card.Content>
                <Card.Content extra>
                    <Icon name="crosshairs" />
                    <Link to={`/group/score-level/${group.shortId}`}>Scores</Link>
                    <br />
                    <Icon name="mail" />
                    <Link to={`/group/messages`}>Messages ({messageCount})</Link>
                </Card.Content>
            </Card>
        </div>
    )
}

export default withRouter(GroupInfo)
