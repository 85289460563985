import { apiServer } from '../settings'
import { getToken } from './token'
import createLog from '../utils/create-log'

const setUserSoftBanOverride = (userGuid, userAuthorization, game, guid, type, override, done) => {
	if (!userGuid) return done('missing userGuid')
	if (!userAuthorization) return done('missing userAuthorization')
	if (!game) return done('missing game')
	if (!guid) return done('missing guid')
	if (!type) return done('missing type')
	if (!override) return done('missing override')

	const headers = new Headers({
		Authorization: 'Bearer ' + getToken(),
		'Content-Type': 'application/json',
	})

	const options = {
		method: 'PUT',
		headers,
		mode: 'cors',
		cache: 'default',
		body: JSON.stringify({
			log: createLog(userGuid, userAuthorization, game, `soft ban override ${guid} ${type} ${override}`),
		}),
	}

	// user-soft-ban-chat-override
	const route = `user-soft-ban-${type}-override`
	const url = `${apiServer}/${route}?game=${game}&guid=${guid}&override=${override}`

	fetch(url, options)
		.then(function(response) {
			return response.json()
		})
		.then(function(response) {
			// console.log('RESPONSE', response)
			if (response.success === false) return done(response.err)
			if (response.success === true) return done(null, response.data.player)
			if (!response.success) return done('response without success true|false')
		})
}

export default setUserSoftBanOverride
