import React, { useEffect, useState, useContext } from 'react'
// import { useParams } from 'react-router-dom'

import { getGroupsInfo } from '../../api'

import GroupList from '../../Groups/GroupList'
import ErrorMessage from '../../ErrorMessage'
import Loader from '../../Loader'

import { Context } from '../../Context'

const UserGroupListLoader = () => {
	const {player, game } = useContext(Context)
	const [errorMsg, setErrorMsg] = useState(null)
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState(null)

	useEffect(() => {
		// console.log('PLAYER', player)
		// console.log('********* RIMUOVERE *********')
		// if (player) player.groups = ['xxxxx','yyyyyy']
		setLoading(true)
		getGroupsInfo(game, player.groups, (err, data) => {
			setLoading(false)
			if (err) return setErrorMsg(err)
			setData(data)
			
		})
	}, [game, player])

	// console.log('GROUPS=>',data)
	if (loading) return <Loader />
	if (data) return <GroupList groups={data.groups}/>
	if (errorMsg) return <ErrorMessage title="Error" body={errorMsg} />
	return null
}

export default UserGroupListLoader
